import Image from 'next/image'
import { RefObject } from 'react'

type TSubtileImage = {
  idx: number
  activeSubtileIdx: number
  imageRef: RefObject<HTMLImageElement | null>
  imageUrl: string
  altText: string
  isSubtileImageFadeInPrevented: boolean
}

export const SubtileImage = ({
  idx,
  activeSubtileIdx,
  imageRef,
  imageUrl,
  altText,
  isSubtileImageFadeInPrevented,
}: TSubtileImage) => {
  const active = idx === activeSubtileIdx
  const imgSrc = `${imageUrl}?w=${imageRef.current?.clientWidth || 375}&q=75&dpr=2&auto=format`

  return (
    <Image
      ref={imageRef}
      src={imgSrc}
      alt={altText}
      fill
      priority
      className={`subtile-image object-cover object-left-top
      ${isSubtileImageFadeInPrevented ? 'duration-[25]' : 'duration-700'}
      ${active ? 'opacity-100 transition-opacity ease-in-out' : 'opacity-0 transition-opacity ease-in-out'}`}
    />
  )
}
