'use client'

import { useUserState } from '~/components/userProvider'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

function AuthenticatedLayout({ children }: { children: React.ReactNode }) {
  const userState = useUserState()
  const router = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    if (userState.isLoading || userState.user) return
    router.replace(`/login?redirectToPath=${pathname}`)
  })

  if (userState.isLoading || !userState.user) return <></>

  return <>{children}</>
}

export const getLayout = (children: React.ReactNode): React.ReactNode => {
  return <AuthenticatedLayout>{children}</AuthenticatedLayout>
}

export default AuthenticatedLayout
