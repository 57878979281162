import { MouseEvent, TouchEvent, useLayoutEffect, useRef } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { logFirebaseEvent } from '~/lib/firebase'
import { useUserState } from '../userProvider'

type TTileTitle =
  | { type: 'shaded' | 'similar' | 'search'; title: string }
  | { type: 'headerWithAction'; title: string; handleGoBack: () => void }

const TileTitle = (props: TTileTitle) => {
  const userState = useUserState()
  const titleWrapperDivRef = useRef<HTMLDivElement>(null)
  const titleSpanRef = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    const baseFontSize = 14
    if (!titleSpanRef.current || !titleWrapperDivRef.current) return
    const { width: titleSpanElementWidth } = titleSpanRef.current.getBoundingClientRect()
    const { width: titleWrapperDivElementWidth } = titleWrapperDivRef.current.getBoundingClientRect()
    if (titleSpanElementWidth >= titleWrapperDivElementWidth) {
      const fontSize = Math.floor((baseFontSize * titleWrapperDivElementWidth) / titleSpanElementWidth)

      titleSpanRef.current.style.fontSize = `${fontSize - 2}px`
    }
  }, [titleSpanRef, titleWrapperDivRef])

  if (props.type === 'headerWithAction') {
    return (
      <div className="absolute z-10 cf-tile-tile-container left-4 right-4 flex flex-row justify-start items-center gap-4">
        <div
          onClick={(e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
            e.stopPropagation()
            logFirebaseEvent('tap_back_button', userState.user?.id)
            props.handleGoBack()
          }}
          className="bg-background-inverse_primary h-9 w-9 rounded-full flex justify-center items-center"
        >
          <ChevronLeftIcon className="h-6 w-6 text-content-inverse_terniary" />
        </div>
        <div className="shadow-tileTitle bg-background-inverse_primary text-content-inverse_primary subpixel-antialiased w-fit px-2 py-1.5 label_large">
          <span>{props.title}</span>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={titleWrapperDivRef}
      className={`
        shadow-tileTitle bg-background-inverse_primary text-content-inverse_primary 
        subpixel-antialiased w-fit
        ${props.type === 'shaded' ? 'p-2.5 label_large' : ''}
        ${props.type === 'search' ? 'px-2 py-1.5 label_small' : ''}
        ${props.type === 'similar' ? 'p-2 label_small max-w-full' : ''}
      `}
    >
      <span ref={titleSpanRef}>{props.title}</span>
    </div>
  )
}

export default TileTitle
