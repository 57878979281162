'use client'

import { useState, useEffect } from 'react'
import { Tile, TileTypeEnum, UserPreferences } from '~/types'
import { sendEventForTile, EventTypes } from '~/lib/algoliaEvents'
import { useUserState } from '~/components/userProvider'
import { logFirebaseEvent } from '~/lib/firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { getUserPreference, setUserPreference } from '~/lib/userPreferences'
import { InterestingIcon } from '~/components/icons'

type TInterestingAction = {
  postItem: Tile
  type: TileTypeEnum
}

const InterestingAction = ({ postItem, type }: TInterestingAction) => {
  const [isTileLikedByUser, setIsTileLikedByUser] = useState(false)
  const [shouldAnimateInterestingIcon, setShouldAnimateInterestingIcon] = useState(false)
  const [showInterestingBanner, setShowInterestingBanner] = useState(false)
  const userState = useUserState()

  useEffect(() => {
    const { likedTileIds } = userState
    if (likedTileIds.includes(postItem.id)) {
      setIsTileLikedByUser(true)
    } else {
      setIsTileLikedByUser(false)
    }
  }, [userState, postItem])

  useEffect(() => {
    if (showInterestingBanner) {
      setTimeout(() => {
        setShowInterestingBanner(false)
      }, 3000)
    }
  }, [showInterestingBanner])

  const handleLikeActionClick = async (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (isTileLikedByUser) {
      setShouldAnimateInterestingIcon(false)
      setIsTileLikedByUser(false)
      userState.unlikeTile(postItem.id)
      logFirebaseEvent('unlike_tile', userState.user?.id, { tileId: postItem.id, type: type })
    } else {
      sendEventForTile(EventTypes.conversion, 'Like Tile', postItem.id, userState.user?.id)
      setShouldAnimateInterestingIcon(true)
      setIsTileLikedByUser(true)
      if (await checkAndUpdateUserPreferencesForInterestingBanner()) setShowInterestingBanner(true)
      userState.likeTile(postItem.id)
      logFirebaseEvent('like_tile', userState.user?.id, { tileId: postItem.id, type: type })
    }
  }

  const isTileIdSavedBefore = (userPreferences: { id: string }[]) => {
    return (
      userPreferences.findIndex((userPreference: { id: string }) => {
        return userPreference.id === postItem.id
      }) >= 0
    )
  }
  const checkAndUpdateUserPreferencesForInterestingBanner = async () => {
    const userPreferences = JSON.parse((await getUserPreference(UserPreferences.INTERESTING)) as string)

    if (userPreferences === null) {
      setUserPreference(UserPreferences.INTERESTING, JSON.stringify([{ id: postItem.id }]))

      return true
    } else if (userPreferences.length === 1) {
      if (isTileIdSavedBefore(userPreferences)) return false

      setUserPreference(UserPreferences.INTERESTING, JSON.stringify([...userPreferences, { id: postItem.id }]))
      return true
    }

    return false
  }

  return (
    <div className="relative">
      <AnimatePresence>
        {showInterestingBanner && (
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'tween', ease: 'easeOut', duration: 0.25 }}
            className="relative"
          >
            <div className="absolute -top-14 -right-2 whitespace-nowrap bg-brand-quaternary border-none px-5 py-2 rounded-full block">
              <span className="text-[16px] font-bold leading-6">you&apos;ll see more like this</span>
            </div>
            <div className="absolute bg-brand-quaternary border-none interesting-clip-path h-[18px] w-4 -top-[18px] right-4" />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        onClick={handleLikeActionClick}
        className="w-12 h-12 p-0.5 cursor-pointer flex justify-center items-center box-border"
      >
        <div
          className={`
            w-full h-full 
            rounded-full border border-stroke-opaque
            flex justify-center items-center
            transition-colors duration-500 ease-out
            ${isTileLikedByUser ? 'bg-brand-quaternary' : 'bg-background-primary'}
          `}
        >
          <InterestingIcon
            className={`h-5 w-5 fill-white ${shouldAnimateInterestingIcon && 'wobbly-pulse-animation'}`}
          />
        </div>
      </div>
    </div>
  )
}

export default InterestingAction
