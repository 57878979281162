'use client'

import { AnimatePresence, motion } from 'framer-motion'
import { some } from 'lodash'

type TSubtileIndicatorItem = {
  color: string
  active: boolean
  shouldHideIndicators: boolean
  indicatorHeight: number
  showConnector: boolean
}

const SubtileIndicatorItem = ({
  color,
  active,
  shouldHideIndicators,
  indicatorHeight,
  showConnector,
}: TSubtileIndicatorItem) => {
  return (
    <div className="relative">
      <motion.div
        layout
        transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
        className="w-1.5"
        style={{
          backgroundColor: color,
          height: indicatorHeight,
          opacity: active ? 1 : shouldHideIndicators ? 0 : 0.5,
        }}
      />
      <AnimatePresence>
        {active && showConnector && (
          <motion.div
            initial={{ width: '0px', top: indicatorHeight / 2 }}
            animate={{ width: '20px', top: indicatorHeight / 2 }}
            exit={{ width: '0px', top: indicatorHeight / 2 }}
            transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
            className="absolute right-1 h-1"
            style={{ backgroundColor: color, top: indicatorHeight / 2 }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default SubtileIndicatorItem
