import Image from 'next/image'
import { SHADED_TILE_FAVICON_LIMIT, STATIC_IMAGES } from '~/lib/constants'
import { getSourceImage } from '~/lib/tileDisplay'
import { Subtile } from '~/types'

type TShadedTileFaviconWrapper = {
  subtiles: Subtile[]
}

const ShadedTileFaviconWrapper = ({ subtiles }: TShadedTileFaviconWrapper) => {
  return (
    <div className="flex flex-row justify-start items-center -space-x-2">
      {subtiles.slice(0, SHADED_TILE_FAVICON_LIMIT).map((subtile, idx) => {
        return (
          <div key={idx} className="relative h-[22px] w-[22px]" style={{ zIndex: idx }}>
            <div className="absolute inset-0 h-[22px] w-[22px] border border-white bg-transparent rounded-full z-[5] shadow-favicon" />
            <Image
              width={20}
              height={20}
              alt={subtile.source as string}
              className="absolute inset-0 h-[22px] w-[22px] rounded-full z-[3] bg-[#D6D6D6]"
              src={getSourceImage(subtile)}
            />
            <Image
              width={20}
              height={20}
              alt={subtile.source as string}
              className="absolute inset-0 h-[22px] w-[22px] rounded-full z-[1]"
              src={STATIC_IMAGES.FAVICON_PLACEHOLDER}
            />
          </div>
        )
      })}
      {subtiles.length > 5 && (
        <div
          className="h-[22px] w-[22px] border-1 border-stroke-selected bg-[#D6D6D6] rounded-full flex flex-row justify-center items-center"
          style={{ zIndex: SHADED_TILE_FAVICON_LIMIT }}
        >
          <span
            className={`
              ${subtiles.length - 5 >= 10 ? 'text-[10px]' : 'text-[11px]'}
              leading-[12px] font-bold text-content-inverse_primary
            `}
          >{`+${subtiles.length - 5}`}</span>
        </div>
      )}
    </div>
  )
}

export default ShadedTileFaviconWrapper
