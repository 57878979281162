'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { IMAGE_ASPECT_RATIOS, ME_PAGE_IMAGE_ASPECT_RATIO, STATIC_IMAGES } from '~/lib/constants'
import { Tile } from '~/types'
import TileTitle from '../tiles/tileTitle'

type TSavedTilePostItem = {
  tile: Tile
  elementWidth: number
}

const SavedTilePostItem = ({ tile, elementWidth }: TSavedTilePostItem) => {
  const router = useRouter()

  const handleTileClick = () => {
    router.push(`/me/tiles/${tile.slug}`)
  }

  const imageUrl =
    tile.imageUrls.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['1X2'])?.url || STATIC_IMAGES.PLACEHOLDER

  return (
    <div onClick={handleTileClick} className="relative w-full h-full">
      <Image
        src={`${imageUrl}?w=${Math.floor(elementWidth)}&q=75&dpr=2&auto=format`}
        alt={tile.title}
        width={elementWidth}
        height={elementWidth / ME_PAGE_IMAGE_ASPECT_RATIO}
        className="object-cover object-left-top"
        style={{ height: elementWidth / ME_PAGE_IMAGE_ASPECT_RATIO }}
      />
      <div className="absolute bottom-3 left-2 right-3 max-w-[calc(100%_-_24px)] z-20">
        <TileTitle type="search" title={tile.title} />
      </div>
    </div>
  )
}

export default SavedTilePostItem
