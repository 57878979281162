import { doc, getDoc, setDoc, deleteDoc, query, collection, orderBy, getDocs } from 'firebase/firestore'
import { db } from '~/lib/firebase'
import { UserTake, UserTakeForUser, UserTakesWithTile } from '~/types'
import { getAllTilesByIds } from './queries'

export const setUserTake = async (tileId: string, userId: string, takeId: number): Promise<void> => {
  const takesRef = doc(db, `tiles/${tileId}/userTakes`, userId)
  await setDoc(takesRef, {
    userId,
    takeId,
    updatedAt: new Date(),
  })
}

export const removeUserTake = async (tileId: string, userId: string): Promise<void> => {
  const takesRef = doc(db, `tiles/${tileId}/userTakes`, userId)
  await deleteDoc(takesRef)
}

export const fetchUserTakeForTile = async (tileId: string, userId: string): Promise<UserTake | undefined> => {
  const takesRef = doc(db, `tiles/${tileId}/userTakes`, userId)
  const userTake = await getDoc(takesRef)
  return userTake.data() as UserTake
}

export const fetchUserTakesForTile = async (tileId: string): Promise<UserTake[]> => {
  const q = query(collection(db, `tiles/${tileId}/userTakes`))
  const userTakesForTileDocs = await getDocs(q)

  const userTakesForTile = userTakesForTileDocs.docs.map((userTakeDoc) => {
    return userTakeDoc.data() as UserTake
  })

  return userTakesForTile
}

export const getAllUserTakes = async (userId: string): Promise<UserTakesWithTile[]> => {
  const q = query(collection(db, `users/${userId}/takes`), orderBy('modifiedAt', 'desc'))
  const userTakesDocs = await getDocs(q)

  const userTakes = userTakesDocs.docs.map((userTakeDoc) => {
    return userTakeDoc.data() as UserTakeForUser
  })

  const userTakeTileIds = userTakes.map((take) => take.tileId)
  const tiles = await getAllTilesByIds(userTakeTileIds)

  const userTakeTiles = [] as UserTakesWithTile[]
  userTakes.forEach((take) => {
    const tile = tiles.find((tile) => tile.id === take.tileId)
    if (!tile) return

    userTakeTiles.push({
      takeId: take?.takeId,
      takes: tile.takes,
      takesPrompt: tile.takesPrompt,
      tileId: tile.id,
      title: tile.title,
      imageUrls: tile.imageUrls,
      slug: tile.slug,
    })
  })

  return userTakeTiles
}
