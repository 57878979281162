'use client'

import Image from 'next/image'
import { animated, easings, useSpring } from '@react-spring/web'
import { DragState, SharedGestureState, useGesture } from '@use-gesture/react'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  useHomeFeedVerticalGestureState,
  GestureDirection,
  useIsHomeFeedHorizontalGestureAllowedOnAndroidState,
} from '~/components/gestureProvider'
import { useFeedState } from '~/components/home/feedProvider'
import { useTutorialsState } from '~/components/tutorials/tutorialsProvider'
import { useUserState } from '~/components/userProvider'
import { IMAGE_ASPECT_RATIOS, STATIC_IMAGES } from '~/lib/constants'
import { Gestures, RenderedShadedTileType, Tile, TileTypeEnum, UserTutorials } from '~/types'
import TileTitle from '../tileTitle'
import TileActionZone from '../tileActionZone'
import Tutorial from '~/components/tutorials/tutorial'
import SwipableShadedTileDetailWrapper from './swipableShadedTileDetailWrapper'
import ReactDOM from 'react-dom'
import { httpsCallable } from 'firebase/functions'
import { functions, logFirebaseEvent } from '~/lib/firebase'
import { logAppsFlyerEvent } from '~/lib/appsFlyerEvents'
import { EventTypes, sendEventForTile } from '~/lib/algoliaEvents'
import { useApplicationConfigsState } from '~/components/applicationConfigsProvider'
import { useNavigationStackState } from '../navigationStackProvider'

type TShadedTileCover = {
  postItem: Tile
  positionalIdx: number
}

enum DragGestureAction {
  SWIPE = 'swipe',
  DRAG = 'drag',
  RELEASE = 'release',
}

const minSwipeVelocity = 0.5
const maxSwipeDuration = 220
const animationDuration = 250

const ShadedTileCover = ({ postItem, positionalIdx }: TShadedTileCover) => {
  const userState = useUserState()
  const applicationConfigsState = useApplicationConfigsState()
  const tutorialsState = useTutorialsState()
  const navigationStackState = useNavigationStackState()
  const { currentPostIdx } = useFeedState()
  const { setGestureState } = useHomeFeedVerticalGestureState()
  const { isHomeFeedHorizontalGestureAllowedOnAndroid, setIsHomeFeedHorizontalGestureAllowedOnAndroid } =
    useIsHomeFeedHorizontalGestureAllowedOnAndroidState()

  const imageRef = useRef<HTMLImageElement | null>(null)
  const { ref: wrapperRef, inView: wrapperInView } = useInView({ threshold: 0.5 })
  const [dragXConfig, setDragXConfig] = useState({
    position: 0, // renderPositions[RenderedShadedTileType.HOMEFEED_COVER]
    duration: 1,
  })
  const [elementInView, setElementInView] = useState<RenderedShadedTileType>(RenderedShadedTileType.HOMEFEED_COVER)
  const [swipableShadedTileDetailRenderID, setSwipableShadedTileDetailRenderID] = useState(0)
  const [hasRead, setHasRead] = useState(false)

  const imageUrl =
    postItem.imageUrls?.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['1X2'])?.url ||
    STATIC_IMAGES.PLACEHOLDER
  const swipableTileDetailsPortalDOMNode = document.getElementById('swipable-tile-details-portal')
  const preventDragGesture = !(currentPostIdx === positionalIdx) || navigationStackState.shouldRenderVisitedTilesSection
  const renderPositions: { [key in RenderedShadedTileType]: number } = {
    [RenderedShadedTileType.HOMEFEED_COVER]: 0,
    [RenderedShadedTileType.SHADED_TILE_DETAILS]: -applicationConfigsState.width,
  }

  function shouldRenderSwipableShadedTileDetail() {
    if (applicationConfigsState.deviceInfo?.platform !== 'android') {
      return currentPostIdx === positionalIdx
    } else {
      return currentPostIdx === positionalIdx && isHomeFeedHorizontalGestureAllowedOnAndroid
    }
  }

  const [shadedTileCoverSpring, shadedTileCoverApi] = useSpring(() => ({ x: 0 }))

  useEffect(() => {
    if (wrapperInView && !hasRead) {
      const readPost = httpsCallable(functions, 'userEvents-read_tile')
      readPost({ tileId: postItem.id })
      logFirebaseEvent('read_tile', userState.user?.id, { tileId: postItem.id, isDeveloping: postItem.developing })
      logAppsFlyerEvent('read_tile')
      setHasRead(true)
    }
  }, [wrapperInView, positionalIdx, postItem, userState, hasRead])

  useEffect(() => {
    if (dragXConfig.position === renderPositions[RenderedShadedTileType.HOMEFEED_COVER])
      setElementInView(RenderedShadedTileType.HOMEFEED_COVER)

    if (dragXConfig.position === renderPositions[RenderedShadedTileType.SHADED_TILE_DETAILS])
      setElementInView(RenderedShadedTileType.SHADED_TILE_DETAILS)

    shadedTileCoverApi.start({
      x: dragXConfig.position,
      config: { duration: dragXConfig.duration, easing: easings.linear },
      onRest: () => {
        if (dragXConfig.position === renderPositions[RenderedShadedTileType.HOMEFEED_COVER])
          setSwipableShadedTileDetailRenderID((prevState) => prevState + 1)

        if (dragXConfig.position === renderPositions[RenderedShadedTileType.HOMEFEED_COVER]) {
          setGestureState(Gestures.HOMEFEED)
        } else {
          setGestureState(Gestures.SWIPABLE_SHADED_TILE)
        }
      },
    })

    // eslint-disable-next-line
  }, [dragXConfig])

  useEffect(() => {
    if (navigationStackState.shouldAnimateInShadedTileCover) {
      setDragXConfig({ position: renderPositions[RenderedShadedTileType.HOMEFEED_COVER], duration: 1 })
      navigationStackState.setShouldAnimateInShadedTileCover(false)
    }

    // eslint-disable-next-line
  }, [navigationStackState.shouldAnimateInShadedTileCover])

  useEffect(() => {
    if (elementInView === RenderedShadedTileType.HOMEFEED_COVER) {
      setTimeout(() => {
        setIsHomeFeedHorizontalGestureAllowedOnAndroid(false)
      }, animationDuration)
    } else {
      setIsHomeFeedHorizontalGestureAllowedOnAndroid(true)
    }
  }, [elementInView])

  const shadedTileCoverDragBind = useGesture(
    {
      onDrag: (dragState: DragState & SharedGestureState) => {
        if (preventDragGesture) return
        if (!isHomeFeedHorizontalGestureAllowedOnAndroid) setIsHomeFeedHorizontalGestureAllowedOnAndroid(true)

        let hasOnGoingSwipeGesture = false
        let isSwipeGestureEventsFired = false

        if (dragState.memo) {
          const {
            xy: [x],
            initial: [xInitial],
            elapsedTime,
            dragging,
          } = dragState

          const movement = x - xInitial
          const relativeVelocity = movement / elapsedTime

          const checkDragGestureAction = (dragGestureActionType: DragGestureAction) => {
            const isSwipeAllowedBasedOnTime = elapsedTime < maxSwipeDuration
            const isSwipeAllowedBasedOnRelativeVelocity = Math.abs(relativeVelocity) >= minSwipeVelocity

            if (dragGestureActionType === DragGestureAction.SWIPE) {
              return !hasOnGoingSwipeGesture && isSwipeAllowedBasedOnTime && isSwipeAllowedBasedOnRelativeVelocity
            } else if (dragGestureActionType === DragGestureAction.DRAG) {
              return dragging && !hasOnGoingSwipeGesture && !isSwipeAllowedBasedOnTime
            } else {
              return !dragging && !hasOnGoingSwipeGesture
            }
          }

          if (checkDragGestureAction(DragGestureAction.SWIPE)) {
            hasOnGoingSwipeGesture = true
            handleSwipe(movement, dragState.memo.isSwipeGestureEventsFired)
            isSwipeGestureEventsFired = true
          }

          if (checkDragGestureAction(DragGestureAction.DRAG)) {
            handleDrag(movement, elementInView)
          }

          if (checkDragGestureAction(DragGestureAction.RELEASE)) {
            handleSnapToFinalPosition(movement)
          }
        }

        return { hasOnGoingSwipeGesture, isSwipeGestureEventsFired }
      },
    },
    {
      drag: {
        axis: 'x',
        preventScroll: true,
        preventScrollAxis: 'y',
        bounds: {
          right: renderPositions[RenderedShadedTileType.HOMEFEED_COVER],
          left: renderPositions[RenderedShadedTileType.SHADED_TILE_DETAILS],
        },
        pointer: { touch: true, capture: true },
      },
    },
  )

  const showHomeFeedCover = () => {
    logFirebaseEvent('swipe_back', userState.user?.id, { tileId: postItem.id })
    setDragXConfig({
      position: renderPositions[RenderedShadedTileType.HOMEFEED_COVER],
      duration: animationDuration,
    })
  }

  const showShadedTileDetails = () => {
    setDragXConfig({
      position: renderPositions[RenderedShadedTileType.SHADED_TILE_DETAILS],
      duration: animationDuration,
    })
  }

  const handleClickTile = () => {
    if (elementInView === RenderedShadedTileType.HOMEFEED_COVER) {
      setIsHomeFeedHorizontalGestureAllowedOnAndroid(true)
      showShadedTileDetails()

      tutorialsState.updateTutorialStatusByType(UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL)
      sendEventForTile(EventTypes.click, 'Tap Tile', postItem.id, userState.user?.id)
      logFirebaseEvent('tap_tile', userState.user?.id, { tileId: postItem.id })
      logAppsFlyerEvent('tap_tile')
    }
  }

  const handleSwipe = (movement: number, isSwipeGestureEventsFired: boolean) => {
    const swipeDirection = movement > 0 ? GestureDirection.RIGHT : GestureDirection.LEFT

    if (swipeDirection === GestureDirection.LEFT && !isSwipeGestureEventsFired) {
      showShadedTileDetails()

      tutorialsState.updateTutorialStatusByType(UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL)
      sendEventForTile(EventTypes.click, 'Tap Tile', postItem.id, userState.user?.id)
      logFirebaseEvent('tap_tile', userState.user?.id, { tileId: postItem.id })

      logAppsFlyerEvent('tap_tile')
    }

    if (swipeDirection === GestureDirection.RIGHT) {
      showHomeFeedCover()
    }
  }

  const handleDrag = (movement: number, elementInView: RenderedShadedTileType) => {
    const swipeDirection = movement > 0 ? GestureDirection.RIGHT : GestureDirection.LEFT

    if (elementInView === RenderedShadedTileType.HOMEFEED_COVER) {
      if (swipeDirection === GestureDirection.RIGHT) return

      setDragXConfig({
        position: renderPositions[RenderedShadedTileType.HOMEFEED_COVER] + movement,
        duration: 1,
      })
    } else {
      if (swipeDirection === GestureDirection.LEFT) return

      setDragXConfig({
        position: renderPositions[RenderedShadedTileType.SHADED_TILE_DETAILS] + movement,
        duration: 1,
      })
    }
  }

  const handleSnapToFinalPosition = (movement: number) => {
    const swipeDirection = movement > 0 ? GestureDirection.RIGHT : GestureDirection.LEFT

    if (swipeDirection === GestureDirection.RIGHT) showHomeFeedCover()

    if (swipeDirection === GestureDirection.LEFT) showShadedTileDetails()
  }

  return (
    <animated.div
      ref={wrapperRef}
      {...shadedTileCoverDragBind()}
      onClick={handleClickTile}
      className="relative flex h-[calc(100dvh_-_71px)] w-screen"
      style={{ left: shadedTileCoverSpring.x }}
    >
      <div className="absolute inset-0 bg-background-primary">
        <Image
          ref={imageRef}
          src={`${imageUrl}?w=${imageRef.current?.clientWidth || 375}&q=75&dpr=2&auto=format`}
          alt={postItem.title}
          priority
          fill
          className="object-cover"
        />
      </div>
      <div className="absolute bottom-0 h-[330px] w-full bg-gradient-to-t from-[rgba(0,0,0,1)] to-[rgba(0,0,0,0)]" />
      <div className="absolute bottom-0 h-fit w-full flex flex-col p-4 gap-4">
        <TileTitle type="shaded" title={postItem.title} />
        <div className="text-content-primary paragraph_medium opacity-80">{postItem.summary}</div>
        <TileActionZone tile={postItem} type={TileTypeEnum.SHADED} />
      </div>
      {currentPostIdx === positionalIdx &&
        applicationConfigsState.deviceInfo?.platform === 'android' &&
        !shouldRenderSwipableShadedTileDetail() && (
          <Image
            src={`${
              postItem.subtiles[0].imageUrls.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['195X9'])?.url ||
              STATIC_IMAGES.PLACEHOLDER
            }?w=${imageRef.current?.clientWidth || 375}&q=75&dpr=2&auto=format`}
            alt="test"
            priority
            fill
            className="hidden"
          />
        )}
      {swipableTileDetailsPortalDOMNode &&
        shouldRenderSwipableShadedTileDetail() &&
        ReactDOM.createPortal(
          <SwipableShadedTileDetailWrapper
            key={`${postItem.id}-${swipableShadedTileDetailRenderID}`}
            postItem={postItem}
            dragXConfig={dragXConfig}
            handleGoBack={() => {
              setDragXConfig({
                position: renderPositions[RenderedShadedTileType.HOMEFEED_COVER],
                duration: animationDuration,
              })
            }}
            handleBackToFeed={() => {
              setDragXConfig({
                position: renderPositions[RenderedShadedTileType.HOMEFEED_COVER],
                duration: animationDuration,
              })
            }}
          />,
          swipableTileDetailsPortalDOMNode,
        )}
      {tutorialsState.shouldShowTutorialByTileId(UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL, postItem.id) && (
        <Tutorial type={UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL} />
      )}
      {tutorialsState.shouldShowTutorialByTileId(UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL, postItem.id) && (
        <Tutorial type={UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL} />
      )}
    </animated.div>
  )
}

export default ShadedTileCover
