'use client'

import { useSearchParams } from 'next/navigation'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Category, SearchResultsPageType } from '~/types'
import SearchInput from './searchInput'
import SearchResultsWrapper from './searchResultsWrapper'
import CategoryResultsWrapper from './categoryResultsWrapper'

interface ISearchContext {
  categoryList: Category[]
  setCategoryList: (categories: Category[]) => void
  searchInputValue: string
  setSearchInputValue: (searchInputValue: string) => void
  canUpdateSearchInputValue: boolean
  setCanUpdateSearchInputValue: (arg: boolean) => void
}

export const SearchContext = createContext<ISearchContext | null>(null)

export default function SearchProvider({ children }: { children: ReactNode }) {
  const searchParams = useSearchParams()
  const [categoryList, setCategoryList] = useState<Category[]>([])
  const [searchInputValue, setSearchInputValue] = useState('')
  const [canUpdateSearchInputValue, setCanUpdateSearchInputValue] = useState(false)
  const [shouldShowSearchResultsPortal, setShouldShowSearchResultsPortal] = useState(false)

  const type = searchParams.get('t')
  const query = searchParams.get('q')
  const searchResultsPortalDOMNode = document.getElementById('search-results-portal')

  useEffect(() => {
    if (!canUpdateSearchInputValue) {
      if (type && query) {
        setSearchInputValue(query)
        setShouldShowSearchResultsPortal(true)
      } else {
        setSearchInputValue('')
        setShouldShowSearchResultsPortal(false)
      }
    }

    // eslint-disable-next-line
  }, [searchParams, type, query])

  const context = {
    categoryList,
    setCategoryList,
    searchInputValue,
    setSearchInputValue,
    canUpdateSearchInputValue,
    setCanUpdateSearchInputValue,
  }

  return (
    <SearchContext.Provider value={context}>
      {children}
      {searchResultsPortalDOMNode &&
        shouldShowSearchResultsPortal &&
        ReactDOM.createPortal(
          <div className="fixed top-0 left-0 w-screen h-[calc(100dvh_-_72px)] z-[2] bg-background-primary flex flex-col cf-safe-area-padding">
            <SearchInput />
            {type === SearchResultsPageType.SEARCH && <SearchResultsWrapper />}
            {type === SearchResultsPageType.CATEGORY && <CategoryResultsWrapper categorySlug={query as string} />}
          </div>,
          searchResultsPortalDOMNode,
        )}
    </SearchContext.Provider>
  )
}

export const useSearchCategories = () => {
  const searchContext = useContext(SearchContext)
  if (!searchContext) throw new Error("Can't call useSearchCategories before context is loaded")
  const { categoryList, setCategoryList } = searchContext
  return { categoryList, setCategoryList }
}

export const useSearchInputValue = () => {
  const searchContext = useContext(SearchContext)
  if (!searchContext) throw new Error("Can't call useSearchInputValue before context is loaded")
  const { searchInputValue, setSearchInputValue } = searchContext
  return { searchInputValue, setSearchInputValue }
}

export const useCanUpdateSearchInputValue = () => {
  const searchContext = useContext(SearchContext)
  if (!searchContext) throw new Error("Can't call useCanUpdateSearchInputValue before context is loaded")
  const { canUpdateSearchInputValue, setCanUpdateSearchInputValue } = searchContext
  return { canUpdateSearchInputValue, setCanUpdateSearchInputValue }
}
