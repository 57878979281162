import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Gestures, Subtile, UserTutorials } from '~/types'
import { LinkIcon } from '../icons'
import { sendEventForTile, EventTypes } from '~/lib/algoliaEvents'
import { useUserState } from '~/components/userProvider'
import { openUrlWithInAppBrowser } from '~/lib/capacitor'
import { formatBulletedText, getSourceAction, getSourceImage } from '~/lib/tileDisplay'
import { logFirebaseEvent } from '~/lib/firebase'
import Image from 'next/image'
import { logAppsFlyerEvent } from '~/lib/appsFlyerEvents'
import { useTutorialsState } from '../tutorials/tutorialsProvider'
import { useHomeFeedVerticalGestureState } from '../gestureProvider'
import { usePathname } from 'next/navigation'
import { STATIC_IMAGES } from '~/lib/constants'

type TSubtileItem = {
  parentTileId: string
  idx: number
  subtile: Subtile
  color: string
  subtileCount: number
  activeSubtileIdx: number
  setActiveSubtileIdx: (idx: number) => void
  showExtendedContent: boolean
  setShowExtendedContent: (flag: boolean) => void
  subtileHeight: number
  setSubtileHeights: (cb: (subtileHeights: number[]) => number[]) => void
  snapIntoDedicatedPositionAfterClick: (idx: number) => void
}

const subtileItemLayoutTransition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.5,
}

const SubtileItem = ({
  parentTileId,
  idx,
  subtile,
  color,
  subtileCount,
  activeSubtileIdx,
  setActiveSubtileIdx,
  showExtendedContent,
  setShowExtendedContent,
  subtileHeight,
  setSubtileHeights,
  snapIntoDedicatedPositionAfterClick,
}: TSubtileItem) => {
  const userState = useUserState()
  const pathname = usePathname()
  const tutorialsState = useTutorialsState()
  const { gestureState } = useHomeFeedVerticalGestureState()
  const subtileItemRef = useRef<HTMLDivElement | null>(null)
  const active = activeSubtileIdx === idx
  const idxDiff = Math.abs(activeSubtileIdx - idx)
  const subtileOpacity = 1 - idxDiff * 0.25 <= 0 ? 0.15 : 1 - idxDiff * 0.25
  const [faviconLoaded, setFaviconLoaded] = useState(false)
  const [sourceImageSrc, setSourceImageSrc] = useState<string | undefined>(undefined)

  useLayoutEffect(() => {
    setSourceImageSrc(getSourceImage(subtile))
  }, [subtile])

  useEffect(() => {
    const isInTilesPage = pathname?.includes('/tiles/') || gestureState === Gestures.SWIPABLE_SHADED_TILE
    if (active && isInTilesPage) {
      logFirebaseEvent('view_subtile', userState.user?.id, { subtileId: subtile.id, index: idx })
    }
  }, [active, idx, subtile, gestureState])

  useEffect(() => {
    const handleHeight = () => {
      setSubtileHeights((prevState: number[]) => [
        ...prevState.slice(0, idx),
        subtileItemRef.current?.clientHeight as number,
        ...prevState.slice(idx + 1, subtileCount - 1),
      ])
    }

    const subtileItemResizeObserver: ResizeObserver = new ResizeObserver(() => {
      if (subtileItemRef.current) handleHeight()
    })

    if (subtileItemRef.current) {
      if (subtileItemRef.current.clientHeight !== subtileHeight) handleHeight()

      subtileItemResizeObserver.observe(subtileItemRef.current)
    }

    return () => {
      subtileItemResizeObserver.disconnect()
    }
  }, [subtileItemRef, idx, subtileCount, subtileHeight, setSubtileHeights])

  const isSubtileExpansionTutorialAvailable = () => {
    return !tutorialsState.tutorialsStatus[UserTutorials.SUBTILE_EXPANSION_TUTORIAL]
  }

  const handleClick = () => {
    if (!active) {
      if (isSubtileExpansionTutorialAvailable()) return
      setShowExtendedContent(false)
      setActiveSubtileIdx(idx)
      snapIntoDedicatedPositionAfterClick(idx)
    } else {
      if (!showExtendedContent) {
        sendEventForTile(EventTypes.click, 'Expand Subtile', parentTileId, userState.user?.id)
        logFirebaseEvent('expand_subtile', userState.user?.id, { subtileId: subtile.id, index: idx })
        logAppsFlyerEvent('expand_subtile')
      } else {
        logFirebaseEvent('collapse_subtile', userState.user?.id, { subtileId: subtile.id, index: idx })
      }
      setShowExtendedContent(!showExtendedContent)
    }
  }

  return (
    <motion.div
      layout
      transition={subtileItemLayoutTransition}
      ref={subtileItemRef}
      onClick={handleClick}
      className="relative w-full h-auto flex flex-col justify-start items-start gap-0"
    >
      <motion.div
        layout
        transition={subtileItemLayoutTransition}
        className="relative box-border h-fit border-[4px] bg-background-primary/80 p-4 z-[1] w-full"
        style={{
          borderColor: active ? color : 'transparent',
          opacity: subtileOpacity,
        }}
      >
        <div className="relative -mt-8 -ml-4 mb-1 w-[28px] h-[28px]" style={{ backgroundClip: 'padding-box' }}>
          {sourceImageSrc && (
            <Image
              width={28}
              height={28}
              alt={(subtile.source as string) || 'subtile source logo'}
              className={`block absolute inset-0 rounded-full h-[28px] w-[28px] z-[3] 
                ${faviconLoaded ? 'bg-white' : 'bg-black'}
              `}
              style={{ backgroundClip: 'border-box' }}
              loading="eager"
              src={sourceImageSrc}
              onLoad={() => setFaviconLoaded(true)}
            />
          )}
          <Image
            width={28}
            height={28}
            alt={(subtile.source as string) || 'subtile source logo'}
            className="block absolute inset-0 rounded-full h-[28px] w-[28px] z-[1] bg-white"
            style={{ backgroundClip: 'border-box' }}
            loading="eager"
            src={STATIC_IMAGES.FAVICON_PLACEHOLDER}
          />
        </div>
        <div className="label_small text-content-primary mr-4">{subtile.title}</div>
        {active && (
          <div
            className="absolute -bottom-[2px] -right-[2px] h-12 w-12 flex justify-end items-end subtile-expand-clip-path"
            style={{ backgroundColor: color, borderColor: color, display: active ? 'flex' : 'hidden' }}
          >
            {!showExtendedContent && <ChevronDownIcon className="w-6 h-6 text-content-inverse_primary" />}
          </div>
        )}
      </motion.div>
      <AnimatePresence>
        {active && showExtendedContent && (
          <motion.div
            layout
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={subtileItemLayoutTransition}
            className="w-full overflow-y-hidden flex flex-col justify-start items-start -mt-[3px] pt-[3px] border-0 z-[2]"
            style={{ backgroundColor: color, borderColor: color }}
          >
            {subtile.summary && (
              <div className="px-8 py-2 box-content -mt-[1px] pt-[10px]">
                <ul className="space-y-1.5 list-disc list-item paragraph_small text-content-inverse_primary">
                  {formatBulletedText(subtile.summary).map((summaryItem, idx) => {
                    return <li key={idx}>{summaryItem}</li>
                  })}
                </ul>
              </div>
            )}
            <div className="w-full flex flex-row justify-between items-center pl-3 pr-1 pb-1 -mt-1 cursor-pointer">
              <div
                className="flex flex-row justify-start items-center gap-2 p-2 pr-4"
                onClick={(e) => {
                  e.stopPropagation()
                  openUrlWithInAppBrowser(subtile.sourceUrl)
                  sendEventForTile(EventTypes.click, 'Click Subtile Source', parentTileId, userState.user?.id)
                  logFirebaseEvent('click_subtile_source', userState.user?.id, {
                    subtileId: subtile.id,
                    index: idx,
                  })
                }}
              >
                <>
                  <LinkIcon className="h-5 w-5 fill-content-inverse_primary" />
                  <span className="text-content-inverse_primary paragraph_x_small">
                    {getSourceAction(subtile)} {subtile.source}
                  </span>
                </>
              </div>
              <ChevronUpIcon className="ml-auto w-6 h-6 text-content-inverse_primary" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default SubtileItem
