'use client'

import { range } from 'lodash'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { TILES_PER_REQUEST_LIMIT } from '~/lib/constants'
import { getCategoryTilePostsIds, getFeedTiles } from '~/lib/queries'
import { LightTile, Tile } from '~/types'
import { useUserState } from '../userProvider'
import LoadingPostItem from './loadingPostItem'
import { useSearchInputValue } from './searchProvider'
import TilePostItem from './tilePostItem'

const fetchPostsStaticPromise = () => new Promise((r) => setTimeout(r, 1000))

const CategoryResultsWrapper = ({ categorySlug }: { categorySlug: string }) => {
  const userState = useUserState()
  const router = useRouter()
  const { searchInputValue, setSearchInputValue } = useSearchInputValue()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { ref: showMoreRef, inView: showMoreInView } = useInView({ threshold: 1 })

  const [posts, setPosts] = useState<Tile[]>([])
  const [elementWidth, setElementWidth] = useState(175)
  const [initialCategoryPageLoading, setInitialCategoryPageLoading] = useState(true)
  const [canLoadMorePosts, setCanLoadMorePosts] = useState(true)
  const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false)
  const [page, setPage] = useState(1)
  const [feedTileIds, setFeedTileIds] = useState<string[]>([])

  useEffect(() => {
    if (containerRef.current) {
      setElementWidth((containerRef.current?.clientWidth - 8) / 2)
    }
  }, [searchInputValue, categorySlug, setSearchInputValue, router])

  useEffect(() => {
    setIsLoadingMorePosts(true)
    getCategoryTilePostsIds({ categorySlug, userId: userState.user?.id }).then((categoryResultIds) => {
      setFeedTileIds(categoryResultIds as string[])
    })
  }, [categorySlug, userState])

  useEffect(() => {
    if (canLoadMorePosts && !isLoadingMorePosts && showMoreInView) {
      setIsLoadingMorePosts(true)
    }
  }, [canLoadMorePosts, isLoadingMorePosts, showMoreInView])

  useEffect(() => {
    if (isLoadingMorePosts && feedTileIds.length > 0) {
      const minLoadWaitPromise = fetchPostsStaticPromise()
      const fetchCategoryPostsPromise = getFeedTiles({
        page: page,
        limit: TILES_PER_REQUEST_LIMIT,
        feedTileIds,
      })

      Promise.all([fetchCategoryPostsPromise, minLoadWaitPromise]).then(async ([response]) => {
        if (response.length < TILES_PER_REQUEST_LIMIT) {
          setCanLoadMorePosts(false)
        }
        setPosts([...posts, ...response])
        setIsLoadingMorePosts(false)
        initialCategoryPageLoading && setInitialCategoryPageLoading(false)
        setPage(page + 1)
      })
    }

    // eslint-disable-next-line
  }, [isLoadingMorePosts, userState, feedTileIds])

  return (
    <div className="relative w-screen bg-background-primary px-4 pb-4 overflow-y-scroll no-scrollbar scroll-smooth flex flex-col space-y-2">
      <div ref={containerRef} className="grid grid-cols-2 gap-2">
        {posts.map((post) => {
          return (
            <TilePostItem
              eventName="tap_category_result"
              key={post.id}
              tile={post as LightTile}
              elementWidth={elementWidth}
            />
          )
        })}
        {isLoadingMorePosts &&
          range(TILES_PER_REQUEST_LIMIT).map((idx) => {
            return <LoadingPostItem key={idx} elementWidth={elementWidth} />
          })}
      </div>
      {canLoadMorePosts && !initialCategoryPageLoading && (
        <div ref={showMoreRef} className="w-full h-4 text-center py-12" />
      )}
    </div>
  )
}

export default CategoryResultsWrapper
