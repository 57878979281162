'use client'

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { httpsCallable } from 'firebase/functions'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { DevelopingIcon } from '~/components/icons'
import { functions } from '~/lib/firebase'
import { Tile, TileTypeEnum, UserTutorials } from '~/types'
import { AnimatePresence, motion } from 'framer-motion'
import { formatBulletedText } from '~/lib/tileDisplay'
import { logFirebaseEvent } from '~/lib/firebase'
import { useUserState } from '~/components/userProvider'
import { STATIC_IMAGES } from '~/lib/constants'
import TileActionZone from '../tileActionZone'
import { useTutorialsState } from '~/components/tutorials/tutorialsProvider'
import Tutorial from '~/components/tutorials/tutorial'
import { logAppsFlyerEvent } from '~/lib/appsFlyerEvents'

type TDevelopingTileCover = {
  postItem: Tile
  positionalIdx: number
}

const layoutTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.4,
}

const DevelopingTileCover = ({ postItem, positionalIdx }: TDevelopingTileCover) => {
  const userState = useUserState()
  const tutorialsState = useTutorialsState()
  const backgroundImageRef = useRef<HTMLImageElement | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const { ref: wrapperRef, inView: wrapperInView } = useInView({ threshold: 0.5 })
  const [contentExtended, setContentExtended] = useState(false)
  const [hasRead, setHasRead] = useState(false)

  useEffect(() => {
    if (wrapperInView && isDevelopingTileExpansionTutorialAvailable()) {
      tutorialsState.setTutorialIdByType(UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL, postItem.id)
    }
    // eslint-disable-next-line
  }, [wrapperInView])

  useEffect(() => {
    if (wrapperInView) {
      if (!hasRead) {
        const readTile = httpsCallable(functions, 'userEvents-read_tile')
        readTile({ tileId: postItem.id })
        logFirebaseEvent('read_tile', userState.user?.id, { tileId: postItem.id, isDeveloping: postItem.developing })
        logAppsFlyerEvent('read_tile')
        setHasRead(true)
      }
    }
  }, [wrapperInView, positionalIdx, postItem, userState, hasRead])

  const developingImageUrl =
    postItem.imageUrls.find((imageUrl) => {
      return imageUrl.aspectRatio === 'developing'
    })?.url || STATIC_IMAGES.PLACEHOLDER

  const isDevelopingTileExpansionTutorialAvailable = () => {
    return (
      !tutorialsState.tutorialsStatus[UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL] &&
      tutorialsState.tutorialIds[UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL] === null
    )
  }

  return (
    <div
      ref={wrapperRef}
      onClick={() => {
        if (contentExtended == false) {
          logFirebaseEvent('developing_tile_extended', userState.user?.id, { tileId: postItem.id })
        }
        setContentExtended(!contentExtended)
        tutorialsState.updateTutorialStatusByType(UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL)
      }}
      className="relative flex h-[calc(100dvh_-_71px)] w-screen bg-background-primary"
    >
      <div className="z-20 backdrop-blur bg-black/40 absolute inset-0 bottom-[1px]" />
      <div className="absolute inset-0 z-10 bg-background-primary">
        <Image
          ref={backgroundImageRef}
          src={`${developingImageUrl}?w=${imageRef.current?.clientWidth || 375}&q=75&dpr=2&auto=format`}
          alt={`${postItem.title}-backdrop-blur-image`}
          priority
          fill
          className="object-cover object-left-top"
        />
      </div>
      <div className="absolute bottom-0 h-fit w-full z-30 flex flex-col p-4 gap-4">
        <div className="relative w-full min-h-[180px]">
          <Image
            ref={imageRef}
            src={`${developingImageUrl}?w=${imageRef.current?.clientWidth || 375}&q=75&dpr=2&auto=format`}
            alt={postItem.title}
            fill
            className="object-cover"
          />
        </div>
        <div className="w-full flex flex-col gap-1.5">
          <h1 className="label_large text-content-primary">{postItem.title}</h1>
          <div className="w-full flex flex-row justify-start items-center gap-1">
            <DevelopingIcon className="h-4 w-4" />
            <span className="paragraph_x_small text-content-primary drop-shadow-sm">developing topic</span>
            {contentExtended ? (
              <ChevronUpIcon className="h-6 w-6 ml-auto bg-transparent" />
            ) : (
              <ChevronDownIcon className="h-6 w-6 ml-auto bg-transparent" />
            )}
          </div>
        </div>
        <div className="text-content-primary paragraph_medium text-opacity-80">{postItem.summary}</div>
        <AnimatePresence>
          {contentExtended && (
            <motion.div
              layout
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={layoutTransition}
              className="w-full overflow-y-hidden flex flex-col justify-start items-start"
            >
              {postItem.bullets && (
                <div className="px-8 py-2 box-content">
                  <ul className="space-y-1.5 list-disc list-item paragraph_small text-content-primary text-opacity-80">
                    {formatBulletedText(postItem.bullets).map((bulletsItem, idx) => (
                      <li key={idx}>{bulletsItem}</li>
                    ))}
                  </ul>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <TileActionZone tile={postItem} type={TileTypeEnum.DEVELOPING} />
      </div>
      {tutorialsState.shouldShowTutorialByTileId(UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL, postItem.id) && (
        <Tutorial type={UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL} />
      )}
    </div>
  )
}

export default DevelopingTileCover
