import { ShareIcon } from '~/components/icons'

type TShareAction = {
  share: (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void
}

const ShareAction = ({ share }: TShareAction) => {
  return (
    <div onClick={share} className="w-12 h-12 p-0.5 cursor-pointer flex justify-center items-center box-border">
      <div className="bg-background-primary w-full h-full rounded-full border border-stroke-opaque flex justify-center items-center">
        <ShareIcon className="h-6 w-6" />
      </div>
    </div>
  )
}

export default ShareAction
