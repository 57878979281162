'use client'

import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { getUserPreference, setUserPreference } from '~/lib/userPreferences'
import { UserTutorials } from '~/types'

interface ITutorialsContext {
  isReady: boolean
  tutorialsStatus: {
    [key in UserTutorials]: boolean
  }
  tutorialIds: {
    [key in UserTutorials]: string | null
  }
  setTutorialIdByType: (type: UserTutorials, id: string) => void
  updateTutorialStatusByType: (type: UserTutorials) => void
  shouldShowTutorialByTileId: (type: UserTutorials, tileId: string) => boolean
  shouldShowTutorial: (type: UserTutorials) => boolean
}

export const TutorialsContext = createContext<ITutorialsContext | null>(null)

export default function TutorialsProvider({ children }: { children: ReactNode }) {
  const [isLoadedFromUserTutorials, setIsLoadedFromUserTutorials] = useState(false)
  const [tutorialsStatus, setTutorialsStatus] = useState({
    [UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL]: false,
    [UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL]: false,
    [UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL]: false,
    [UserTutorials.SUBTILE_EXPANSION_TUTORIAL]: false,
  })
  const [tutorialIds, setTutorialIds] = useState({
    [UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL]: null,
    [UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL]: null,
    [UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL]: null,
    [UserTutorials.SUBTILE_EXPANSION_TUTORIAL]: null,
  })

  useEffect(() => {
    Promise.allSettled([
      getTutorialStateFromUserTutorials(UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL),
      getTutorialStateFromUserTutorials(UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL),
      getTutorialStateFromUserTutorials(UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL),
      getTutorialStateFromUserTutorials(UserTutorials.SUBTILE_EXPANSION_TUTORIAL),
    ]).finally(() => {
      setIsLoadedFromUserTutorials(true)
    })
  }, [])

  const getTutorialStateFromUserTutorials = async (type: UserTutorials) => {
    const status = JSON.parse((await getUserPreference(type)) as string)

    if (status === null) setUserPreference(type, JSON.stringify(false))

    if (status) setTutorialsStatus((prevState) => ({ ...prevState, [type]: true }))
  }

  const setTutorialIdByType = (type: UserTutorials, id: string) => {
    setTutorialIds((prevState) => ({ ...prevState, [type]: id }))
  }

  const updateTutorialStatusByType = (type: UserTutorials) => {
    setTutorialsStatus((prevState) => ({ ...prevState, [type]: true }))
    setTutorialIds((prevState) => ({ ...prevState, [type]: null }))
    setUserPreference(type, JSON.stringify(true))
  }

  const shouldShowTutorialByTileId = (type: UserTutorials, tileId: string) => {
    return !tutorialsStatus[type] && tileId === tutorialIds[type]
  }

  const shouldShowTutorial = (type: UserTutorials) => {
    return !tutorialsStatus[type]
  }

  const context = {
    isReady: isLoadedFromUserTutorials,
    tutorialsStatus,
    tutorialIds,
    setTutorialIdByType,
    updateTutorialStatusByType,
    shouldShowTutorialByTileId,
    shouldShowTutorial,
  }

  return <TutorialsContext.Provider value={context}>{children}</TutorialsContext.Provider>
}

export const useTutorialsState = (): ITutorialsContext => {
  const tutorialsState = useContext(TutorialsContext)
  if (!tutorialsState) throw new Error("Can't call useTutorialsState before the context is loaded")
  return tutorialsState
}
