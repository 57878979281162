'use client'

import { useEffect, useState } from 'react'
import { getAllUserTakes } from '~/lib/userTakes'
import { useUserState } from '../userProvider'
import { useMeState } from './meProvider'
import { UserTakesWithTile } from '~/types'
import SavedUserTakeItem from './savedUserTakeItem'
import { usePathname } from 'next/navigation'

const SavedTakes = () => {
  const { user } = useUserState()
  const pathname = usePathname()
  const meState = useMeState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAllUserTakes(user?.id as string).then((fetchedUserTakes) => {
      if (!fetchedUserTakes) return

      meState.setSavedTakes(fetchedUserTakes)
      setIsLoading(false)
    })
  }, [pathname])

  if (!isLoading && meState.savedTakes.length === 0) {
    return (
      <div className="px-9 w-full h-full overflow-y-scroll">
        <span className="heading_small">record your take at the bottom of a shades page, and it will appear here.</span>
      </div>
    )
  }

  return (
    <div className="relative w-full bg-background-primary px-4 pb-4 overflow-y-scroll no-scrollbar scroll-smooth flex flex-col space-y-2 gap-[42px]">
      {meState.savedTakes.map((savedTake: UserTakesWithTile) => (
        <SavedUserTakeItem key={savedTake.takeId} userTake={savedTake} />
      ))}
    </div>
  )
}

export default SavedTakes
