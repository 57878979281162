'use client'

import { animated, easings, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { httpsCallable } from 'firebase/functions'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'
import { useApplicationConfigsState } from '~/components/applicationConfigsProvider'
import { useUserState } from '~/components/userProvider'
import { functions, logFirebaseEvent } from '~/lib/firebase'
import { Tile } from '~/types'
import { useNavigationStackState } from '../navigationStackProvider'
import ShadedTileDetail from './shadedTileDetail'

type TShadedTileDetailWrapper = {
  tile: Tile
  disableSlideInAnim?: boolean
  fromSearch?: boolean
  fromMePage?: boolean
}

const ShadedTileDetailWrapper = ({
  tile,
  disableSlideInAnim = false,
  fromSearch = false,
  fromMePage = false,
}: TShadedTileDetailWrapper) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const userState = useUserState()
  const { width: applicationWidth } = useApplicationConfigsState()
  const navigationStackState = useNavigationStackState()
  const shadedTileDetailContainerRef = useRef<HTMLDivElement | null>(null)

  const [shadedTileDetailContainerSpring, shadedTileDetailContainerApi] = useSpring(() =>
    disableSlideInAnim
      ? { x: 0 }
      : {
          from: { x: shadedTileDetailContainerRef.current?.clientWidth || applicationWidth },
          to: { x: 0 },
        },
  )

  const shadedTileDetailContainerDragBind = useDrag(
    ({ offset, dragging }) => {
      const dragOffsetX = offset[0]

      if (dragging) {
        shadedTileDetailContainerApi.start({ x: dragOffsetX })
      } else {
        if (dragOffsetX >= 150) {
          logFirebaseEvent('swipe_back', userState.user?.id, { tileId: tile.id })
          handleGoBack()
        } else {
          shadedTileDetailContainerApi.start({ x: 0, config: { duration: 250, easing: easings.linear } })
        }
      }
    },
    {
      preventScroll: true,
      axis: 'x',
      bounds: { left: 0, right: window.innerWidth },
      rubberband: true,
      threshold: 25,
    },
  )

  useEffect(() => {
    if (!tile) return
    const tappedPost = httpsCallable(functions, 'userEvents-tap_tile')
    const readTile = httpsCallable(functions, 'userEvents-read_tile')
    tappedPost({ tileId: tile.id })
    readTile({ tileId: tile.id })
  })

  const handleGoBack = () => {
    if (fromSearch || pathname?.includes('/search')) {
      shadedTileDetailContainerApi.start({
        x: applicationWidth,
        config: { duration: 250, easing: easings.linear },
        onRest: () => {
          navigationStackState.popFromVisitedTiles()
        },
      })
    } else if (fromMePage || pathname?.includes('/me')) {
      shadedTileDetailContainerApi.start({
        x: applicationWidth,
        config: { duration: 250, easing: easings.linear },
        onRest: () => {
          navigationStackState.popFromVisitedTiles()
        },
      })
    } else {
      shadedTileDetailContainerApi.start({
        x: applicationWidth,
        config: { duration: 250, easing: easings.linear },
        onRest: () => {
          navigationStackState.popFromVisitedTiles()
        },
      })
    }
  }

  const handleBackToFeed = () => {
    if (fromSearch || pathname?.includes('/search')) {
      const t = searchParams.get('t')
      const q = searchParams.get('q')
      navigationStackState.resetVisitedTilesStack()
      shadedTileDetailContainerApi.start({
        x: applicationWidth,
        config: { duration: 250, easing: easings.linear },
        onRest: () => {
          router.replace(`/search?t=${t}&q=${q}`)
        },
      })
    } else if (fromMePage || pathname?.includes('/me/')) {
      navigationStackState.resetVisitedTilesStack()
      shadedTileDetailContainerApi.start({
        x: applicationWidth,
        config: { duration: 250, easing: easings.linear },
        onRest: () => {
          router.replace('/me')
        },
      })
    } else {
      navigationStackState.resetVisitedTilesStack()
      shadedTileDetailContainerApi.start({
        x: applicationWidth,
        config: { duration: 250, easing: easings.linear },
        onRest: () => {
          router.replace('/')
        },
      })
    }
  }

  return (
    <animated.div
      {...shadedTileDetailContainerDragBind()}
      ref={shadedTileDetailContainerRef}
      className="fixed inset-0 z-[3] bg-background-primary dynamic-screen-min-height touch-none select-none"
      style={{
        x: shadedTileDetailContainerSpring.x,
      }}
    >
      <ShadedTileDetail
        tile={tile}
        handleGoBack={handleGoBack}
        handleBackToFeed={handleBackToFeed}
        fromSearch={fromSearch}
        fromMePage={fromMePage}
      />
    </animated.div>
  )
}

export default ShadedTileDetailWrapper
