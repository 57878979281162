'use client'

import { animated, easings, useSpring } from '@react-spring/web'
import { useEffect } from 'react'
import { useApplicationConfigsState } from '~/components/applicationConfigsProvider'
import { Tile } from '~/types'
import ShadedTileDetail from './shadedTileDetail'

type Props = {
  postItem: Tile
  dragXConfig: { position: number; duration: number }
  handleGoBack: () => void
  handleBackToFeed: () => void
}

const SwipableShadedTileDetailWrapper = ({ postItem, dragXConfig, handleGoBack, handleBackToFeed }: Props) => {
  const { width: applicationWidth } = useApplicationConfigsState()
  const [shadedTileCoverSpring, shadedTileCoverApi] = useSpring(() => ({ x: applicationWidth }))

  useEffect(() => {
    shadedTileCoverApi.start({
      x: applicationWidth + dragXConfig.position,
      config: { duration: dragXConfig.duration, easing: easings.linear },
    })
    // eslint-disable-next-line
  }, [shadedTileCoverApi, dragXConfig])

  return (
    <animated.div
      className="w-screen dynamic-screen-min-height absolute top-0 bottom-0 z-[2] bg-background-primary overflow-y-hidden"
      style={{ left: shadedTileCoverSpring.x }}
    >
      <ShadedTileDetail tile={postItem} handleGoBack={handleGoBack} handleBackToFeed={handleBackToFeed} />
    </animated.div>
  )
}

export default SwipableShadedTileDetailWrapper
