'use client'

import MePageStateSelector from './mePageStateSelector'
import { MePageState, useMeState } from './meProvider'
import SavedShades from './savedShades'
import SavedTakes from './savedTakes'

const MeWrapper = () => {
  const meState = useMeState()

  return (
    <div className="fixed top-0 left-0 w-screen h-[calc(100dvh_-_72px)] z-[2] bg-background-primary flex flex-col cf-safe-area-padding">
      <MePageStateSelector />
      {meState.mePageState === MePageState.SAVED_TAKES ? <SavedTakes /> : <SavedShades />}
    </div>
  )
}

export default MeWrapper
