import aa from 'search-insights'

export const sendEventForTile = (
  eventType: EventTypes,
  eventName: string,
  tileId: string,
  userId: string | undefined,
) => {
  if (userId) {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API as string,
    })

    aa(eventType, {
      index: 'tiles_personalized',
      eventName: eventName,
      objectIDs: [tileId],
      userToken: userId,
    })
  }
}

export enum EventTypes {
  click = 'clickedObjectIDs',
  conversion = 'convertedObjectIDs',
}
