'use client'

import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import { getFeedTiles } from '~/lib/queries'
import { HOME_FEED_POST_REQUEST_LIMIT } from '~/lib/constants'
import { Tile } from '~/types'
import { useUserState } from '../userProvider'
import SplashScreen from '../splashScreen'

interface IFeedContext {
  posts: any[]
  setPosts: (posts: any[]) => void
  currentPostIdx: number
  setCurrentPostIdx: (idx: number) => void
  resetHomeFeedToTopPosition: boolean
  setResetHomeFeedToTopPosition: Dispatch<SetStateAction<boolean>>
}

export const FeedContext = createContext<IFeedContext | null>(null)

export default function FeedProvider({ children }: { children: ReactNode }) {
  const userState = useUserState()
  const [posts, setPosts] = useState<Tile[]>([])
  const [currentPostIdx, setCurrentPostIdx] = useState<number>(0)
  const [resetHomeFeedToTopPosition, setResetHomeFeedToTopPosition] = useState(false)
  const [hasMorePosts, setHasMorePosts] = useState(false)
  const [shadedPostsPage, setShadedPostsPage] = useState(1)

  useEffect(() => {
    if (resetHomeFeedToTopPosition) setResetHomeFeedToTopPosition(false)
  }, [resetHomeFeedToTopPosition])

  useEffect(() => {
    const fetchNewPosts = async () => {
      if (userState.userFeedLoaded) {
        const [userTiles] = await Promise.all([
          getFeedTiles({
            page: shadedPostsPage,
            limit: HOME_FEED_POST_REQUEST_LIMIT,
            feedTileIds: userState.userFeedTileIds,
          }),
        ])

        if (posts.length === 0 && userTiles.length === 0) {
          userState.setFeedToDefault()
        }

        if (userTiles.length > 0) {
          setHasMorePosts(true)
        } else {
          setHasMorePosts(false)
          return
        }

        setPosts([...posts, ...userTiles])

        setShadedPostsPage((page) => page + 1)
      }
    }

    const lastPostsIdx = posts.length - 1

    if (posts.length === 0 && userState.userFeedLoaded) {
      fetchNewPosts()
    }

    if (lastPostsIdx - currentPostIdx <= 3 && hasMorePosts) {
      fetchNewPosts()
    }
  }, [currentPostIdx, posts, userState, hasMorePosts, shadedPostsPage])

  const context = {
    posts,
    setPosts,
    currentPostIdx,
    setCurrentPostIdx,
    resetHomeFeedToTopPosition,
    setResetHomeFeedToTopPosition,
  }

  return (
    <FeedContext.Provider value={context}>
      {posts.length === 0 && <SplashScreen />}
      {children}
    </FeedContext.Provider>
  )
}

export const useFeedState = (): IFeedContext => {
  const feedState = useContext(FeedContext)
  if (!feedState) throw new Error("Can't call useFeedState before the context is loaded")
  return feedState
}

export const useSetCurrentPostInFeedState = (): ((idx: number) => void) => {
  const feedState = useContext(FeedContext)
  if (!feedState) throw new Error("Can't call useSetCurrentPostInFeedState before the context is loaded")
  return feedState.setCurrentPostIdx
}
