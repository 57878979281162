'use client'

import Image from 'next/image'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { ForwardedRef, forwardRef, useState } from 'react'
import { IMAGE_ASPECT_RATIOS, STATIC_IMAGES } from '~/lib/constants'
import { LightTile } from '~/types'
import { logFirebaseEvent } from '~/lib/firebase'
import { useUserState } from '~/components/userProvider'
import { HomeActiveIcon } from '../icons'
import TileTitle from '../tiles/tileTitle'

type TSimilarTiles = {
  similarTiles: LightTile[]
  handleBackToFeed: () => void
  fromSearch: boolean
  fromMePage: boolean
}

const SimilarTiles = (
  { similarTiles, handleBackToFeed, fromSearch, fromMePage }: TSimilarTiles,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const searchParams = useSearchParams()
  const router = useRouter()
  const userState = useUserState()
  const pathname = usePathname()
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0 })
  let similarTileIdx = -1

  return (
    <div ref={ref} className="w-full h-fit mt-12 flex flex-col justify-start items-start gap-4">
      <span className="label_large text-content-primary [text-shadow:_2px_2px_3px_rgb(0_0_0_/_100%)]">up next...</span>
      <div
        className="overflow-x-scroll h-64 w-full no-scrollbar flex gap-2 last:pr-4 snap-mandatory snap-x"
        onTouchStart={(e) => {
          setTouchStart({ x: e.touches[0].clientX, y: e.touches[0].clientY })
        }}
        onTouchMove={(e) => {
          const deltaX = e.changedTouches[0].clientX - touchStart.x
          const deltaY = e.changedTouches[0].clientY - touchStart.y
          const horizontalTouch = Math.abs(deltaX) > Math.abs(deltaY)
          if (horizontalTouch) {
            e.stopPropagation()
          }
        }}
      >
        {similarTiles.map((similarTile) => {
          const imageUrl =
            similarTile.imageUrls?.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['1X2'])?.url ||
            STATIC_IMAGES.PLACEHOLDER

          similarTileIdx++
          return (
            <div
              key={similarTile.id}
              className="relative w-32 h-full bg-background-primary snap-start snap-always"
              style={{ minWidth: '128px' }}
              onClick={() => {
                if (fromSearch || pathname?.includes('/search')) {
                  const type = searchParams.get('t')
                  const query = searchParams.get('q')
                  router.push(`/search/tiles/${similarTile.slug}?t=${type}&q=${query}`)
                } else if (fromMePage || pathname?.includes('/me')) {
                  router.push(`/me/tiles/${similarTile.slug}`)
                } else {
                  router.push(`/tiles/${similarTile.slug}`)
                }
                logFirebaseEvent('click_similar_tile', userState.user?.id, {
                  tileId: similarTile.id,
                  index: similarTileIdx,
                })
              }}
            >
              <Image
                src={`${imageUrl}?w=128&q=75&dpr=2&auto=format`}
                alt={similarTile.title}
                fill
                className="object-cover object-left-top"
              />
              <div className="absolute inset-0 z-40 p-2 w-fit max-w-full flex flex-col justify-end items-start">
                <TileTitle type="similar" title={similarTile.title} />
              </div>
            </div>
          )
        })}
      </div>
      <button
        onClick={() => {
          logFirebaseEvent('tap_homefeed_button', userState.user?.id)
          handleBackToFeed()
        }}
        className="bg-background-primary border-stroke-opaque border h-10 w-full flex flex-row p-[10px] justify-center items-center gap-3"
      >
        <HomeActiveIcon className="h-5 w-5" />
        <span className="text-content-primary label_small">back to home feed</span>
      </button>
    </div>
  )
}

export default forwardRef(SimilarTiles)
