import { ArrowLeftIcon, ArrowUpIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import { SHADES_PAGE_BASE_TOP_MARGIN } from '~/lib/constants'
import { UserTutorials } from '~/types'

type ITutorial = {
  type: UserTutorials
  tutorialOffset?: number
}

const tutorialContext: { [key in UserTutorials]?: JSX.Element } = {
  [UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL]: (
    <p className="text-content-primary heading_x_small text-center">
      slide up <br /> to see next topic
    </p>
  ),
  [UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL]: (
    <p className="text-content-primary heading_x_small text-center">
      slide left <br /> to see the shades
    </p>
  ),
  [UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL]: (
    <p className="text-content-primary heading_x_small text-center">
      on developing topics, <br /> tap to expand
    </p>
  ),
  [UserTutorials.SUBTILE_EXPANSION_TUTORIAL]: (
    <p className="text-content-primary heading_x_small text-center">tap a shade to expand it</p>
  ),
}

const tutorialIcon: { [key in UserTutorials]?: JSX.Element } = {
  [UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL]: (
    <div className="relative h-14 w-14">
      <ArrowUpIcon className="absolute -top-2 right-1.5 h-5" />
      <Image
        src="/static-images/tutorial-finger-emoji.png"
        alt="tutorial emoji for HOMEFEED_VERTICAL_SWIPE_TUTORIAL"
        width={48}
        height={48}
        className="h-14 w-14 animate-homefeed-vertical-swipe-tutorial"
      />
    </div>
  ),
  [UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL]: (
    <div className="relative h-14 w-14">
      <ArrowLeftIcon className="absolute -top-2 right-1.5 w-5" />
      <Image
        src="/static-images/tutorial-finger-emoji.png"
        alt="tutorial emoji for SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL"
        width={48}
        height={48}
        className="h-14 w-14 animate-shaded-tile-horizontal-swipe-tutorial"
      />
    </div>
  ),
  [UserTutorials.DEVELOPING_TILE_EXPANSION_TUTORIAL]: (
    <div className="relative h-14 w-14">
      <div className="absolute opacity-0 top-[1px] left-[10px] -rotate-[60deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 -top-[4px] left-[14px] -rotate-[30deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 -top-[6px] left-[20px] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 -top-[4px] left-[26px] rotate-[30deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 top-[1px] left-[30px] rotate-[60deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <Image
        src="/static-images/tutorial-finger-emoji.png"
        alt="tutorial emoji for SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL"
        width={48}
        height={48}
        className="h-14 w-14 animate-developing-tile-expansion-tutorial"
      />
    </div>
  ),
  [UserTutorials.SUBTILE_EXPANSION_TUTORIAL]: (
    <div className="relative h-14 w-14">
      <div className="absolute opacity-0 top-[1px] left-[10px] -rotate-[60deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 -top-[4px] left-[14px] -rotate-[30deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 -top-[6px] left-[20px] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 -top-[4px] left-[26px] rotate-[30deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <div className="absolute opacity-0 top-[1px] left-[30px] rotate-[60deg] h-[7px] w-[3px] bg-background-inverse_primary rounded-full origin-bottom animate-click-effect-appear-tutorial" />
      <Image
        src="/static-images/tutorial-finger-emoji.png"
        alt="tutorial emoji for SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL"
        width={48}
        height={48}
        className="h-14 w-14 animate-developing-tile-expansion-tutorial"
      />
    </div>
  ),
}

const Tutorial = ({ type, tutorialOffset = 0 }: ITutorial): JSX.Element => {
  switch (type) {
    case UserTutorials.SUBTILE_EXPANSION_TUTORIAL: {
      return (
        <AnimatePresence>
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4, ease: 'easeOut', delay: 0.2 }}
            className="absolute inset-0 h-full w-full z-[100] pointer-events-none flex flex-col justify-start items-center"
            style={{ top: SHADES_PAGE_BASE_TOP_MARGIN + tutorialOffset + 16 + 26 }}
          >
            <div className="p-6 w-[280px] h-[166px] bg-background-primary bg-opacity-80 flex flex-col justify-center items-center gap-4">
              {tutorialIcon[type]}
              {tutorialContext[type]}
            </div>
          </motion.div>
        </AnimatePresence>
      )
    }
    default: {
      return (
        <AnimatePresence>
          <motion.div
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4, ease: 'easeOut', delay: 0.2 }}
            className="absolute inset-0 h-full w-full z-[100] pointer-events-none flex flex-col justify-center items-center"
          >
            <div className="p-6 w-[250px] h-[200px] bg-background-primary bg-opacity-80 flex flex-col justify-center items-center gap-4">
              {tutorialIcon[type]}
              {tutorialContext[type]}
            </div>
          </motion.div>
        </AnimatePresence>
      )
    }
  }
}

export default Tutorial
