import { range } from 'lodash'
import { Tile, Subtile } from '~/types'

export const generateGradientFromRandomBrandColorPair = (subtileCount: number) => {
  const brandColors: { [k: string]: number }[] = [
    { r: 220, g: 255, b: 120 }, // #DCFF78
    { r: 255, g: 174, b: 115 }, // #FFAE73
    { r: 255, g: 130, b: 130 }, // #FF8282
    { r: 238, g: 121, b: 202 }, // #EE79CA
    { r: 101, g: 255, b: 165 }, // #65FFA1
    { r: 93, g: 202, b: 249 }, // #5DCAF9
    { r: 145, g: 135, b: 255 }, // #9187FF
    { r: 174, g: 107, b: 247 }, // #AE6BF7
  ]

  const [start, end] = brandColors.sort(() => 0.5 - Math.random()).slice(0, 2)
  const segments = ['r', 'g', 'b']
  const segmentSpreads: { [k: string]: number[] } = {}
  const gradientColors = []

  for (const segment of segments) {
    let segmentSpread

    const diff = end[segment] - start[segment]

    if (diff === 0) {
      segmentSpread = new Array(subtileCount).fill(start[segment])
    } else {
      const stepSize = diff / (subtileCount - 1)
      segmentSpread = range(start[segment], end[segment], stepSize)
      segmentSpread.push(end[segment])
      segmentSpread = segmentSpread.map((v) => Math.floor(v))
    }

    const segmentHexSpread = segmentSpread.map((v) => {
      const hex = v.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    })

    segmentSpreads[segment] = segmentHexSpread
  }

  for (let i = 0; i < subtileCount; i++) {
    const hex = `#${segmentSpreads['r'][i]}${segmentSpreads['g'][i]}${segmentSpreads['b'][i]}`
    gradientColors.push(hex)
  }

  return gradientColors
}

export const formatBulletedText = (text: string) => {
  const textLines = text.split(/\r?\n/)

  return textLines.flatMap((text) => {
    if (text.trim().length === 0) return []
    if (text[0] === '-') return text.substring(1)
    return text
  })
}

export const getSourceFaviconUrl = (sourceUrl: string) => {
  return `https://www.google.com/s2/favicons?domain=${sourceUrl}&sz=28`
}

export const getSourceImage = (tile: Tile | Subtile) => {
  return tile.sourceImage ? tile.sourceImage : getSourceFaviconUrl(tile.sourceUrl)
}
export const getSourceAction = (tile: Tile | Subtile) => {
  return tile.sourceAction ? tile.sourceAction : 'read on'
}
