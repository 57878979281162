import Image from 'next/image'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { IMAGE_ASPECT_RATIOS, STATIC_IMAGES } from '~/lib/constants'
import { fetchUserTakesForTile } from '~/lib/userTakes'
import { UserTake, UserTakesWithTile } from '~/types'

type Props = {
  userTake: UserTakesWithTile
}

const SavedUserTakeItem = ({ userTake }: Props) => {
  const router = useRouter()
  const pathname = usePathname()
  const [bucketedUserTakes, setBuckedtedUserTakes] = useState<{ bucket: { [k: number]: number }; total: number }>({
    bucket: {},
    total: 0,
  })

  async function fetchUserTakes() {
    const UserTakesForTile = await fetchUserTakesForTile(userTake.tileId)

    setBuckedtedUserTakes(generateUserTakeRatios(UserTakesForTile))
  }

  useEffect(() => {
    fetchUserTakes()
  }, [pathname])

  const generateUserTakeRatios = (userTakesForTile: UserTake[]) => {
    const bucketedUserTakes = userTakesForTile.reduce((bucket, ut) => {
      bucket[ut.takeId] ? (bucket[ut.takeId] += 1) : (bucket[ut.takeId] = 1)
      return bucket
    }, {} as { [k: number]: number })

    return { bucket: bucketedUserTakes, total: userTakesForTile.length }
  }

  const imageUrl =
    userTake.imageUrls.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['4X3'])?.url ||
    STATIC_IMAGES.PLACEHOLDER

  const handleTileClick = (tileSlug: string) => {
    router.push(`/me/tiles/${tileSlug}`)
  }

  const calculateBucketHeight = (takeId: number) => {
    return Math.floor((bucketedUserTakes.bucket[takeId] / bucketedUserTakes.total) * 100)
  }

  return (
    <div className="w-full flex flex-col justify-start items-start gap-2">
      <div
        onClick={() => handleTileClick(userTake.slug)}
        className="w-full flex flex-row justify-start items-center gap-4 cursor-pointer"
      >
        <Image
          src={imageUrl}
          alt={userTake.title}
          width={73}
          height={55}
          className="border-[#9F9F9F] border object-cover object-left-top min-w-[73px] min-h-[55px] max-w-[73px] max-h-[55px]"
        />
        <h2 className="label_large text-content-primary">{userTake.takesPrompt}</h2>
      </div>
      <div className="w-full grid grid-cols-3 gap-4">
        {userTake.takes.map((take) => {
          return (
            <div
              key={take.id}
              className="relative py-4 px-3 bg-background-primary bg-opacity-70 flex flex-col justify-start items-center gap-4"
            >
              <div
                className="absolute inset-0 bg-transparent z-[5]"
                style={{
                  border: userTake.takeId === take.id ? '5px solid #FFFFFF' : '1px solid #FFFFFF',
                }}
              />
              {bucketedUserTakes.bucket[take.id] && bucketedUserTakes.total >= 3 && (
                <div
                  className="absolute bottom-0 w-full z-[3] bg-brand-quaternary bg-opacity-70"
                  style={{ height: `${calculateBucketHeight(take.id)}%` }}
                />
              )}
              <Image unoptimized src={take.imageUrl} alt={take.title} width={68} height={68} className="z-[5]" />
              <span
                className="text-center text-content-primary leading-[18px] font-normal text-sm z-[5]"
                style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
              >
                {take.title}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SavedUserTakeItem
