export const convertDate = (date: Date) => {
  const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1

  const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()

  const string = String(date.getFullYear()) + month + day

  return string
}

export const convertLocalDate = (date: Date) => {
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1

  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

  const string = String(date.getFullYear()) + month + day

  return string
}

export const getShortDay = () => {
  const today = new Date()
  const month = today.toLocaleString('default', { month: 'short' })
  return month + ' ' + today.getDate()
}
