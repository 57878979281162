import Image from 'next/image'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { fetchUserTakeForTile, fetchUserTakesForTile, removeUserTake, setUserTake } from '~/lib/userTakes'
import { TUserTakes, UserPreferences, UserTake } from '~/types'
import { logFirebaseEvent } from '~/lib/firebase'
import { useInView } from 'react-intersection-observer'
import { getUserPreference, setUserPreference } from '~/lib/userPreferences'
import { AnimatePresence, motion } from 'framer-motion'

type TUserTakesWrapper = {
  tileId: string
  userId: string
  takesPrompt: string
  takes: TUserTakes
  snapIntoDedicatedPositionAfterClick: () => void
}

const UserTakesWrapper = (
  { tileId, userId, takesPrompt, takes, snapIntoDedicatedPositionAfterClick }: TUserTakesWrapper,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [selectedTakeId, setSelectedTakeId] = useState<number | undefined>(undefined)
  const [viewPortRef, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '0px 0px',
  })
  const [showUserTakesBanner, setShowUserTakesBanner] = useState(false)
  const [bucketedUserTakes, setBuckedtedUserTakes] = useState<{ bucket: { [k: number]: number }; total: number }>({
    bucket: {},
    total: 0,
  })

  useEffect(() => {
    if (inView) {
      logFirebaseEvent('user_takes_in_view', userId)
    }
  }, [inView])

  useEffect(() => {
    setTimeout(() => {
      setShowUserTakesBanner(false)
    }, 5000)
  }, [showUserTakesBanner])

  async function fetchUserTakes() {
    const userTakesForTile = await fetchUserTakesForTile(tileId)

    setBuckedtedUserTakes(generateUserTakeRatios(userTakesForTile))
  }

  useEffect(() => {
    async function fetchUserTake() {
      const userTakeForTile = await fetchUserTakeForTile(tileId, userId)

      if (userTakeForTile) setSelectedTakeId(userTakeForTile.takeId)
    }

    fetchUserTake()
    fetchUserTakes()
  }, [tileId, userId, selectedTakeId])

  useEffect(() => {
    fetchUserTakes()
  }, [selectedTakeId])

  const generateUserTakeRatios = (userTakesForTile: UserTake[]) => {
    const bucketedUserTakes = userTakesForTile.reduce((bucket, ut) => {
      bucket[ut.takeId] ? (bucket[ut.takeId] += 1) : (bucket[ut.takeId] = 1)
      return bucket
    }, {} as { [k: number]: number })

    return { bucket: bucketedUserTakes, total: userTakesForTile.length }
  }

  const handleClick = async (takeId: number) => {
    snapIntoDedicatedPositionAfterClick()
    if (takeId === selectedTakeId) {
      setSelectedTakeId(undefined)
      removeUserTake(tileId, userId)
      logFirebaseEvent('user_take_remove', userId)
    } else {
      setSelectedTakeId(takeId)
      setUserTake(tileId, userId, takeId)
      if (await checkAndUpdateUserPreferencesForUserTakesBanner()) setShowUserTakesBanner(true)
      logFirebaseEvent('user_take_select', userId)
    }
  }

  const isTileIdSavedBefore = (userPreferences: { id: string }[]) => {
    return (
      userPreferences.findIndex((userPreference: { id: string }) => {
        return userPreference.id === tileId
      }) >= 0
    )
  }

  const checkAndUpdateUserPreferencesForUserTakesBanner = async () => {
    const userPreferences = JSON.parse((await getUserPreference(UserPreferences.USER_TAKES)) as string)

    if (userPreferences === null) {
      setUserPreference(UserPreferences.USER_TAKES, JSON.stringify([{ id: tileId }]))

      return true
    } else if (userPreferences.length === 1) {
      if (isTileIdSavedBefore(userPreferences)) return false

      setUserPreference(UserPreferences.USER_TAKES, JSON.stringify([...userPreferences, { id: tileId }]))
      return true
    }

    return false
  }

  const calculateBucketHeight = (takeId: number) => {
    return Math.floor((bucketedUserTakes.bucket[takeId] / bucketedUserTakes.total) * 100)
  }

  return (
    <div ref={viewPortRef}>
      <div ref={ref} className="w-full h-fit mt-12 flex flex-col justify-start items-start gap-4 relative">
        <div className="relative w-full">
          <span className="label_large text-content-primary">{`your take: ${takesPrompt}`}</span>
          <AnimatePresence>
            {showUserTakesBanner && (
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'tween', ease: 'easeOut', duration: 0.25 }}
                className="absolute bottom-10 left-0"
              >
                <div className="relative bg-brand-quaternary border-none px-5 py-2 rounded-full flex flex-col justify-center items-center">
                  <span className="text-[15px] font-bold leading-6">
                    your takes are recorded in the &quot;me&quot; tab
                  </span>
                </div>
                <div className="absolute bg-brand-quaternary border-none interesting-clip-path h-[18px] w-4 -bottom-[17px] left-6" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="w-full grid grid-cols-3 gap-4">
          {takes.map((take) => {
            return (
              <div
                key={take.id}
                onClick={() => handleClick(take.id)}
                className="relative py-4 px-3 bg-background-primary bg-opacity-70 flex flex-col justify-start items-center gap-4"
              >
                <div
                  className="absolute inset-0 bg-transparent z-[5]"
                  style={{
                    border: selectedTakeId === take.id ? '5px solid #FFFFFF' : '1px solid #FFFFFF',
                  }}
                />
                {selectedTakeId && bucketedUserTakes.bucket[take.id] && bucketedUserTakes.total >= 3 && (
                  <div
                    className="absolute bottom-0 w-full z-[3] bg-brand-quaternary bg-opacity-70"
                    style={{ height: `${calculateBucketHeight(take.id)}%` }}
                  />
                )}
                <Image unoptimized src={take.imageUrl} alt={take.title} width={68} height={68} className="z-[5]" />
                <span
                  className="text-center text-content-primary leading-[18px] font-normal text-sm z-[5]"
                  style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
                >
                  {take.title}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default forwardRef(UserTakesWrapper)
