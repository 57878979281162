import { collection, doc, getDoc, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db, functions, logFirebaseEvent } from '~/lib/firebase'
import { CompletePageProps, GamesList, LEAVE_QUIZ_STATE, QuizQuestion } from '~/types'
import { convertLocalDate } from './constants'
import { formatISO, isEqual, parseISO, startOfDay, subDays, addHours } from 'date-fns'

import { httpsCallable } from 'firebase/functions'
import { delayTimeout } from '~/lib/timeout'

export const getTodaysQuizQuestions = async (today: Date): Promise<QuizQuestion[]> => {
  const formattedDate = convertLocalDate(today)

  const quizCollection = collection(db, 'quizzes')
  const quizDoc = await getDoc(doc(quizCollection, 'quiz' + formattedDate))

  const quiz = quizDoc.data()

  await delayTimeout(1500)

  if (quiz !== undefined) {
    return quiz.quiz as QuizQuestion[]
  } else {
    return []
  }
}

// update correct docs and collections when finishing quiz
export const getGlobalHighscore = async (date: Date) => {
  // get the current user from the auth

  const formattedDate = convertLocalDate(date)

  const globalQuizResponsesCollection = collection(db, `quizzes/quiz${formattedDate}/quizResponses`)

  const q = query(globalQuizResponsesCollection, orderBy('score', 'desc'), limit(1))

  const snapshot = await getDocs(q)
  const highscoreDoc = snapshot.docs

  if (highscoreDoc.length > 0) {
    const highScore = highscoreDoc[0].data()
    return highScore.score as number
  }

  return 0
}

// update correct docs and collections when finishing quiz
export const getUserHighscore = async ({ userId }: { userId: string | undefined }) => {
  // get the current user from the auth
  const userQuizCollection = collection(db, `users/${userId}/quizResponses`)

  const q = query(userQuizCollection, orderBy('score', 'desc'), limit(1))

  const snapshot = await getDocs(q)
  const highscoreDoc = snapshot.docs

  if (highscoreDoc.length > 0) {
    const highScore = highscoreDoc[0].data()
    return highScore.score as number
  }

  return 0
}

export const getPlayedToday = async ({ date, userId }: { date: Date; userId?: string }) => {
  const formattedDate = convertLocalDate(date)
  if (!userId) return undefined

  const userQuizCollection = collection(db, `users/${userId}/quizResponses`)

  const quizDoc = await getDoc(doc(userQuizCollection, 'quiz' + formattedDate))

  return quizDoc.exists()
}

export const getTodayScore = async ({ date, userId }: { date: Date; userId?: string }) => {
  const formattedDate = convertLocalDate(date)

  if (!userId) return 0
  let todayScore = 0

  const userQuizCollection = collection(db, `users/${userId}/quizResponses`)

  const quizDoc = await getDoc(doc(userQuizCollection, 'quiz' + formattedDate))
  if (quizDoc?.data()) {
    todayScore = quizDoc?.data()?.score
  }
  return todayScore
}

export const getStreak = async ({ userId }: { userId: string | undefined }) => {
  if (userId === undefined) return 0

  const userQuizResponsesCollection = collection(db, `users/${userId}/quizResponses`)
  const globalQuizResponsesCollection = collection(db, 'quizzes')

  const qUser = query(userQuizResponsesCollection, orderBy('finishedAt', 'desc'))
  const qGLobal = query(globalQuizResponsesCollection, orderBy('createdAt', 'desc'))

  const userSnapshot = await getDocs(qUser)
  const userQuizDocs = userSnapshot.docs

  const globalSnapshot = await getDocs(qGLobal)
  const globalQuizDocs = globalSnapshot.docs

  let streak = 0
  let numSub = 0

  for (let i = 0; i < userQuizDocs.length; i++) {
    const localToday = new Date().getDate()

    const userQuizDay = startOfDay(parseISO(userQuizDocs[i].data().finishedAt))
    const globalQuizDay = startOfDay(addHours(parseISO(globalQuizDocs[i].data().createdAt), 7))

    if (userQuizDay === undefined || globalQuizDay === undefined) return streak

    if (i === 0) {
      // if there is a quiz for tmrw should start comparing one day before
      if (localToday !== globalQuizDay.getUTCDate()) numSub++
      if (isEqual(userQuizDay, subDays(globalQuizDay, numSub))) {
        streak++
        continue
      } else {
        numSub++
        if (isEqual(userQuizDay, subDays(globalQuizDay, numSub))) {
          streak++
          continue
        }
      }
    } else {
      if (isEqual(userQuizDay, subDays(globalQuizDay, numSub))) {
        streak++
        continue
      }
    }
    break
  }

  return streak
}

export const exitQuiz = async (
  status: LEAVE_QUIZ_STATE,
  quizScore: number,
  userId?: string,
): Promise<CompletePageProps> => {
  if (status !== LEAVE_QUIZ_STATE.DONE) {
    const userHighScore = await getUserHighscore({ userId: userId })

    const finishQuiz = httpsCallable(functions, 'quizme-finish_quizme')
    await finishQuiz({
      today: formatISO(new Date()),
      date: convertLocalDate(new Date()),
      score: quizScore !== 0 ? quizScore : -1,
    })

    if (status === LEAVE_QUIZ_STATE.COMPLETE) {
      logFirebaseEvent(`finish_${GamesList.QUIZ_ME}_game`, userId, { score: quizScore })
      const today = new Date()
      const todayScore = await getTodayScore({ date: today, userId: userId })
      const topScore = await getGlobalHighscore(today)
      const newStreak = await getStreak({ userId: userId })
      return {
        todayScore: todayScore,
        userHighScore: userHighScore,
        topScore: topScore,
        newStreak: newStreak,
      }
    } else {
      logFirebaseEvent(`quit_${GamesList.QUIZ_ME}_game`, userId, { score: quizScore })
    }
  }

  return {
    todayScore: 0,
    userHighScore: 0,
    topScore: 0,
    newStreak: 0,
  }
}
