'use client'

import { reduce, range } from 'lodash'
import { animated, easings, useSpring } from '@react-spring/web'
import { httpsCallable } from 'firebase/functions'
import { useEffect, useRef, useState } from 'react'
import { useUserState } from '~/components/userProvider'
import { IMAGE_ASPECT_RATIOS, SHADES_PAGE_BASE_TOP_MARGIN, STATIC_IMAGES } from '~/lib/constants'
import { functions, logFirebaseEvent } from '~/lib/firebase'
import { generateGradientFromRandomBrandColorPair } from '~/lib/tileDisplay'
import { LightTile, Tile, TileTypeEnum, UserTutorials } from '~/types'
import TileTitle from '../tileTitle'
import SimilarTiles from '~/components/tiles/similarTiles'
import SubtileWrapper from '~/components/subtile/subtileWrapper'
import SubtileIndicatorItem from '~/components/subtile/subtileIndicatorItem'
import TileActionZone from '../tileActionZone'
import { DragState, SharedGestureState, useGesture } from '@use-gesture/react'
import { SubtileImage } from '~/components/subtile/subtileImage'
import { useTutorialsState } from '~/components/tutorials/tutorialsProvider'
import Tutorial from '~/components/tutorials/tutorial'
import UserTakesWrapper from '../userTakes/userTakesWrapper'

type TShadedTileDetail = {
  tile: Tile
  handleGoBack: () => void
  handleBackToFeed: () => void
  fromSearch?: boolean
  fromMePage?: boolean
}

const dragConfig = {
  duration: 700,
  easing: easings.easeOutCubic,
}
const dragMultiplier = 1.5

const ShadedTileDetail = ({
  tile,
  handleGoBack,
  handleBackToFeed,
  fromSearch = false,
  fromMePage = false,
}: TShadedTileDetail) => {
  const userState = useUserState()
  const tutorialsState = useTutorialsState()
  const imageRef = useRef<HTMLImageElement | null>(null)
  const shadesScrollContainerRef = useRef<HTMLDivElement | null>(null)
  const userTakesContainerRef = useRef<HTMLDivElement | null>(null)
  const similarShadesContainerRef = useRef<HTMLDivElement | null>(null)

  const [isSubtileImageFadeInPrevented, setIsSubtileImageFadeInPrevented] = useState(true)
  const [unreadSubtileCount, setUnreadSubtileCount] = useState(0)
  const [activeSubtileIdx, setActiveSubtileIdx] = useState(0)
  const [activeSubtileIdxBeforeDrag, setActiveSubtileIdxBeforeDrag] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  // @ts-ignore
  const [subtileWrapperPlacement, setSubtileWrapperPlacement] = useState(SHADES_PAGE_BASE_TOP_MARGIN)
  const [showExtendedContent, setShowExtendedContent] = useState(false)
  const [gradientColors, setGradientColors] = useState<string[]>([])
  const [subtileHeights, setSubtileHeights] = useState<number[]>(new Array(getSectionsCount()).fill(0))
  const [subtileOffsets, setSubtileOffsets] = useState<
    { up: number | null; down: number | null; position: number; basePosition: number }[]
  >(new Array(getSectionsCount()).fill({ up: null, down: null, position: 0, basePosition: 0 }))

  const [shadesScrollContainerSpring, shadesScrollContainerApi] = useSpring(() => ({ y: 0 }), [])

  useEffect(() => {
    if (isSubtileExpansionTutorialAvailable()) {
      tutorialsState.setTutorialIdByType(UserTutorials.SUBTILE_EXPANSION_TUTORIAL, tile.id)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isSubtileExpansionTutorialAvailable) return
    setIsSubtileImageFadeInPrevented(false)
  }, [activeSubtileIdx])

  useEffect(() => {
    const engagedSubtileIdx = Math.ceil(tile.subtiles.length / 2)
    if (activeSubtileIdx !== engagedSubtileIdx) return
    const engagedPost = httpsCallable(functions, 'userEvents-engaged_tile')
    engagedPost({ tileId: tile.id })
  }, [activeSubtileIdx, tile])

  useEffect(() => {
    userState.getUnreadSubtileCountForTile(tile).then((count) => {
      setUnreadSubtileCount(count)
    })
  }, [userState, tile])

  useEffect(() => {
    if (gradientColors.length === 0) {
      setGradientColors(generateGradientFromRandomBrandColorPair(tile.subtiles.length))
    }
  }, [tile.subtiles, gradientColors])

  useEffect(() => {
    setShowExtendedContent(false)
  }, [activeSubtileIdx])

  useEffect(() => {
    if (!isDragging) {
      shadesScrollContainerApi.start({
        y: -1 * subtileOffsets[activeSubtileIdx].basePosition,
        config: { duration: 500, easing: easings.easeOutCubic },
      })
    }
  }, [activeSubtileIdx])

  useEffect(() => {
    if (showExtendedContent && isSubtileExpansionTutorialAvailable()) {
      tutorialsState.updateTutorialStatusByType(UserTutorials.SUBTILE_EXPANSION_TUTORIAL)
    }
  }, [showExtendedContent])

  useEffect(() => {
    setSubtileOffsets((prevState) => {
      const sectionOffsets = tile.subtiles.map((_, idx) => {
        const subtileTopPosition = reduce(subtileHeights.slice(0, idx), (sum, n) => sum + n + 56, 0)

        /**
         * Base position is the top-position of the subtile item, when its size is on the original size.
         * The conditional logic in this calculation only runs once, and then it defaults to the previous base position calculation
         * (either 0 for initial cases or basePosition value for later cases)
         *
         * it checks if the basePosition value of the indicated subtile in the prevState is 0
         * it checks if the subtileTopPosition is NOT a multiplication of 56 and index
         *
         * Between each subtileItem there is a gap which is defined with gap-14 in subtileWrapper.tsx which is 56px
         * If the subtileTopPosition with respect to index is a multiplication of 56, this means the ResizeObserver inside subtileItem.tsx
         * is not finished calculating the height of the subtileItem and registering 0. As there cannot be a subtileItem with 0 height (due to the borders)
         * we need to skip these conditions of subtileTopPosition
         */
        return {
          down: idx === 0 ? null : subtileTopPosition - subtileHeights[idx],
          up: subtileTopPosition + subtileHeights[idx],
          position: subtileTopPosition,
          basePosition:
            prevState[idx].basePosition === 0 && subtileTopPosition !== 56 * idx
              ? subtileTopPosition
              : prevState[idx].basePosition,
        }
      })

      if (hasUserTakesSection() && userTakesContainerRef.current) {
        const userTakesSectionHeight = userTakesContainerRef.current.getBoundingClientRect().height
        const userTakesSectionTopPosition =
          sectionOffsets[sectionOffsets.length - 1].position + subtileHeights[subtileHeights.length - 1] + 56

        const userTakesAdditionalScrollThreshold = 100
        sectionOffsets.push({
          down: userTakesSectionTopPosition - userTakesSectionHeight / 2,
          up: userTakesSectionTopPosition + userTakesSectionHeight / 2 + userTakesAdditionalScrollThreshold,
          position: userTakesSectionTopPosition,
          basePosition: userTakesSectionTopPosition,
        })
      }

      if (similarShadesContainerRef.current) {
        const similarShadesSectionHeight = similarShadesContainerRef.current.getBoundingClientRect().height
        const previousSectionHeight =
          userTakesContainerRef.current?.getBoundingClientRect().height || subtileHeights[subtileHeights.length - 1]
        const similarShadesSectionTopPosition =
          sectionOffsets[sectionOffsets.length - 1].position + previousSectionHeight + 52

        sectionOffsets.push({
          down: similarShadesSectionTopPosition - similarShadesSectionHeight / 2,
          up: similarShadesSectionTopPosition + similarShadesSectionHeight / 2,
          position: similarShadesSectionTopPosition,
          basePosition: similarShadesSectionTopPosition,
        })
      }

      return sectionOffsets
    })

    // eslint-disable-next-line
  }, [subtileHeights])

  function hasUserTakesSection() {
    return tile.takesPrompt && tile.takes.length > 0
  }

  function getUserTakesIdx() {
    return getSectionsCount() - 2
  }

  function getSectionsCount() {
    let sectionsCount = tile.subtiles.length
    if (hasUserTakesSection()) sectionsCount += 1

    // add 1 more for similarTilesSection
    return sectionsCount + 1
  }

  function getImageUrl(idx: number) {
    let imageUrl
    if (idx < tile.subtiles.length) {
      imageUrl = tile.subtiles[idx]?.imageUrls[0]?.url
    } else {
      imageUrl = tile.imageUrls?.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['1X2'])?.url
    }

    return imageUrl || STATIC_IMAGES.PLACEHOLDER
  }

  function getImageAltText(idx: number) {
    if (idx < tile.subtiles.length) {
      return tile.subtiles[idx].title
    } else {
      return tile.title
    }
  }

  const trackDragToChangeActiveSubtileElement = (dragState: SharedGestureState & DragState) => {
    const dragMovementY = dragState.movement[1] * dragMultiplier
    const dragDirectionY = dragState.direction[1]

    const movement = dragMovementY - subtileOffsets[activeSubtileIdxBeforeDrag].position
    const { up, down } = subtileOffsets[activeSubtileIdx]

    if (activeSubtileIdx !== 0 || (activeSubtileIdx === 0 && movement < 100)) {
      shadesScrollContainerApi.start({ y: movement, config: dragConfig })
    }

    if (dragDirectionY === -1) {
      if (up && movement < 0 && Math.abs(movement) > up) {
        setActiveSubtileIdx((prevState) => {
          let nextActiveSubtileId = prevState + 1
          if (nextActiveSubtileId > getSectionsCount() - 1) {
            nextActiveSubtileId = getSectionsCount() - 1
          }
          return nextActiveSubtileId
        })
      }
    } else if (dragDirectionY === 1) {
      if (down && Math.abs(movement) < down) {
        setActiveSubtileIdx((prevState) => {
          let nextActiveSubtileId = prevState - 1
          if (nextActiveSubtileId < 0) {
            nextActiveSubtileId = 0
          }
          return nextActiveSubtileId
        })
      }
    }
  }

  const snapIntoDedicatedPositionAfterRelease = () => {
    setActiveSubtileIdxBeforeDrag(activeSubtileIdx)
    if (hasUserTakesSection() && activeSubtileIdx === getSectionsCount() - 2) {
      logFirebaseEvent('user_takes_position_snap', userState.user?.id)
    }
    shadesScrollContainerApi.start({
      y: -1 * subtileOffsets[activeSubtileIdx].position,
      config: dragConfig,
    })
  }

  const snapIntoDedicatedPositionAfterClick = (idx: number) => {
    shadesScrollContainerApi.start({
      y: -1 * subtileOffsets[idx].basePosition,
      config: dragConfig,
    })
  }

  const calculateIndicatorTopMargin = () => {
    let topMargin = subtileWrapperPlacement + 12

    for (const idx in tile.subtiles) {
      if (Number(idx) < activeSubtileIdx) {
        topMargin -= 24 + 8
      }
    }

    return topMargin
  }

  const isSubtileExpansionTutorialAvailable = () => {
    return !tutorialsState.tutorialsStatus[UserTutorials.SUBTILE_EXPANSION_TUTORIAL]
  }

  useGesture(
    {
      onDrag: (dragState: SharedGestureState & DragState) => {
        if (isSubtileExpansionTutorialAvailable()) return

        if (isDragging !== dragState.dragging) {
          setIsDragging(dragState.dragging as boolean)
        }

        if (dragState.dragging) {
          setShowExtendedContent(false)
          trackDragToChangeActiveSubtileElement(dragState)
        } else {
          snapIntoDedicatedPositionAfterRelease()
        }
      },
      onDragEnd: () => {
        snapIntoDedicatedPositionAfterRelease()
      },
    },
    {
      target: shadesScrollContainerRef,
      drag: {
        axis: 'lock',
        filterTaps: true,
        pointer: { touch: true },
      },
    },
  )

  return (
    <>
      {range(getSectionsCount()).map((subtile, idx) => {
        return (
          <SubtileImage
            key={idx}
            idx={idx}
            activeSubtileIdx={activeSubtileIdx}
            imageRef={imageRef}
            imageUrl={getImageUrl(idx)}
            altText={getImageAltText(idx)}
            isSubtileImageFadeInPrevented={isSubtileImageFadeInPrevented}
          />
        )
      })}
      <div className="absolute bottom-0 h-full w-full bg-gradient-to-t from-[rgba(0,0,0,1)] to-[rgba(0,0,0,0)]" />
      <div className="relative h-full w-full p-4 z-[20]">
        <TileTitle type="headerWithAction" title={tile.title} handleGoBack={handleGoBack} />
        <animated.div
          ref={shadesScrollContainerRef}
          className="w-[calc(100%_-_8px] h-fit overflow-hidden touch-none"
          style={{ y: shadesScrollContainerSpring.y }}
        >
          <SubtileWrapper
            parentTileId={tile.id}
            subtiles={tile.subtiles}
            gradientColors={gradientColors}
            subtileWrapperPlacement={subtileWrapperPlacement}
            unreadSubtileCount={unreadSubtileCount}
            activeSubtileIdx={activeSubtileIdx}
            setActiveSubtileIdx={setActiveSubtileIdx}
            showExtendedContent={showExtendedContent}
            setShowExtendedContent={setShowExtendedContent}
            subtileHeights={subtileHeights}
            setSubtileHeights={setSubtileHeights}
            snapIntoDedicatedPositionAfterClick={snapIntoDedicatedPositionAfterClick}
          />
          {tile.takesPrompt && tile.takes.length > 0 && (
            <UserTakesWrapper
              ref={userTakesContainerRef}
              tileId={tile.id}
              userId={userState.user?.id as string}
              takesPrompt={tile.takesPrompt}
              takes={tile.takes}
              snapIntoDedicatedPositionAfterClick={() => {
                snapIntoDedicatedPositionAfterClick(getUserTakesIdx())
                setActiveSubtileIdx(getUserTakesIdx())
              }}
            />
          )}
          <SimilarTiles
            ref={similarShadesContainerRef}
            similarTiles={tile.similarTiles as LightTile[]}
            handleBackToFeed={handleBackToFeed}
            fromSearch={fromSearch}
            fromMePage={fromMePage}
          />
        </animated.div>
        <div
          className={`
            absolute right-0 top-0
            h-fit w-[14px] py-1
            flex flex-col justify-start items-center gap-2
            ${activeSubtileIdx >= tile.subtiles.length ? 'transparent' : 'bg-background-primary/20'}
            transition-all duration-300 ease-linear
          `}
          style={{ marginTop: `${calculateIndicatorTopMargin()}px` }}
        >
          {tile.subtiles.map((_, idx) => (
            <SubtileIndicatorItem
              key={idx}
              color={gradientColors[idx]}
              active={activeSubtileIdx === idx}
              shouldHideIndicators={activeSubtileIdx >= tile.subtiles.length}
              indicatorHeight={activeSubtileIdx === idx ? subtileHeights[idx] : 24}
              showConnector={false}
            />
          ))}
        </div>
        <div className="absolute bottom-4 right-4">
          <TileActionZone tile={tile} type={TileTypeEnum.SHADED_DETAIL} />
        </div>
        {tutorialsState.shouldShowTutorial(UserTutorials.SUBTILE_EXPANSION_TUTORIAL) && (
          <Tutorial type={UserTutorials.SUBTILE_EXPANSION_TUTORIAL} tutorialOffset={subtileHeights[0]} />
        )}
      </div>
    </>
  )
}

export default ShadedTileDetail
