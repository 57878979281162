import { SEARCH_PAGE_IMAGE_ASPECT_RATIO } from '~/lib/constants'

const LoadingPostItem = ({ elementWidth }: { elementWidth: number }) => {
  return (
    <div
      className="bg-background-terniary animate-pulse cursor-default border border-stroke-transparent"
      style={{ height: elementWidth / SEARCH_PAGE_IMAGE_ASPECT_RATIO }}
    />
  )
}

export default LoadingPostItem
