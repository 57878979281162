'use client'

import { Capacitor } from '@capacitor/core'
import algoliasearch from 'algoliasearch'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { getLayout as getParentLayout } from './authentication/authenticatedLayout'
import HomeFeed from './home/homefeed'
import TabBar from './tabbar'
import { PushNotifications, Token } from '@capacitor/push-notifications'
import { functions } from '~/lib/firebase'
import { httpsCallable } from '@firebase/functions'
import { useUserState } from '~/components/userProvider'
import { logFirebaseEvent } from '~/lib/firebase'
import { isMatchingPathname } from '~/lib/navigation'
import { TabItems } from '~/types'
import ShadedTileDetailWrapper from './tiles/shadedTile/shadedTileDetailWrapper'
import FeedProvider from './home/feedProvider'
import SearchProvider from './search/searchProvider'
import TutorialsProvider from './tutorials/tutorialsProvider'
import { useNavigationStackState } from './tiles/navigationStackProvider'
import MeProvider from './me/meProvider'
import GameProvider from '~/components/games/gamesProvider'

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API || '',
)

export default function TabLayout({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const pathname = usePathname() as string
  const userState = useUserState()
  const navigationStackState = useNavigationStackState()
  const [isPushNotificationEventsFired, setIsPushNotificationEventsFired] = useState(false)

  useEffect(() => {
    if (isPushNotificationEventsFired) {
      setTimeout(() => {
        setIsPushNotificationEventsFired(false)
      }, 500)
    }
  }, [isPushNotificationEventsFired])

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return
    // check permission to use push notifications
    // Android will always be granted
    PushNotifications.checkPermissions().then(async (perms) => {
      let receive = perms.receive

      // if we need to prompt, then do it
      // iOS will prompt user and return if they granted permission or not
      if (perms.receive === 'prompt') {
        receive = (await PushNotifications.requestPermissions()).receive
      }

      // if still not granted, then move on
      if (receive !== 'granted') return

      // otherwise, register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register()
    })

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: Token) => {
      if (!token.value) return
      const refreshToken = httpsCallable(functions, 'notifications-refresh_token')
      await refreshToken({ messagingRegistrationToken: token.value })
    })

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', () => {
      return
    })

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', () => {
      return
    })

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) => {
      if (!isPushNotificationEventsFired) {
        setIsPushNotificationEventsFired(true)
        logFirebaseEvent('push_notification_pressed', userState.user?.id, { notificationId: notification.id })
        const route = notification.notification.data['dl.route'] as string
        if (!route) return
        const slug = route.substring(4) // remove the starting cf:/
        if (!slug) return
        logFirebaseEvent('push_notification_routed', userState.user?.id, {
          notificationId: notification.id,
          slug: slug,
        })
        router.push(slug)
      }
    })
  }, [])

  return (
    <FeedProvider>
      <TutorialsProvider>
        <InstantSearch searchClient={searchClient} stalledSearchDelay={200} indexName="tiles">
          <Configure filters="developing=0" />
          <SearchProvider>
            <GameProvider>
              <MeProvider>
                {navigationStackState.shouldRenderVisitedTilesSection && (
                  <div className="fixed inset-0 w-screen dynamic-screen-min-height z-[5]">
                    {navigationStackState.visitedTiles.map((tile, idx) => (
                      <div
                        key={tile.id}
                        className="fixed inset-0 w-screen dynamic-screen-min-height"
                        style={{ zIndex: idx + 1 }}
                      >
                        <ShadedTileDetailWrapper tile={tile} key={tile.id} disableSlideInAnim={false} />
                      </div>
                    ))}
                  </div>
                )}

                {children}
                <div className="fixed inset-0 w-screen dynamic-screen-min-height bg-background-primary overflow-y-hidden z-[1]">
                  <HomeFeed />
                  <TabBar />
                </div>
                {isMatchingPathname(pathname, TabItems.ME) && <div id="me-page-portal" />}
                {isMatchingPathname(pathname, TabItems.SEARCH) && <div id="search-results-portal" />}
                {isMatchingPathname(pathname, TabItems.HOME) && <div id="swipable-tile-details-portal" />}
              </MeProvider>
            </GameProvider>
          </SearchProvider>
        </InstantSearch>
      </TutorialsProvider>
    </FeedProvider>
  )
}

export const getLayout = (children: React.ReactNode): React.ReactNode => {
  return getParentLayout(<TabLayout>{children}</TabLayout>)
}
