'use client'

import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { QuizQuestion } from '~/types'
import { getTodaysQuizQuestions } from './quizme/lib/queries'

interface IGamesContext {
  currDate: string | undefined
  setCurrDate: Dispatch<SetStateAction<string | undefined>>
  isLoadedQuizMe: boolean
  QuizMeQuestions: QuizQuestion[]
}

export const GamesContext = createContext<IGamesContext | null>(null)

export default function GameProvider({ children }: { children: ReactNode }) {
  const [currDate, setCurrDate] = useState<string | undefined>(undefined)
  const [isLoadedQuizMe, setIsLoadedQuizMe] = useState<boolean>(false)
  const [QuizMeQuestions, setQuizMeQuestions] = useState<QuizQuestion[]>([])

  useEffect(() => {
    const fetchQuestions = async () => {
      setIsLoadedQuizMe(false)
      const today = new Date()
      await getTodaysQuizQuestions(today).then((newQuestions) => {
        setQuizMeQuestions(newQuestions)
        setIsLoadedQuizMe(true)
      })
    }

    if (currDate) {
      fetchQuestions()
    }
  }, [currDate])

  const context = {
    currDate,
    setCurrDate,
    isLoadedQuizMe,
    QuizMeQuestions,
  }

  return <GamesContext.Provider value={context}> {children} </GamesContext.Provider>
}

export const useGamestate = () => {
  const gameState = useContext(GamesContext)
  if (!gameState) throw new Error("Can't call useGameState before the context is loaded")
  return gameState
}
