'use client'

import { range } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { getAllShadedTilesByIds } from '~/lib/queries'
import { useUserState } from '../userProvider'
import LoadingPostItem from './loadingPostItem'
import { useMeState } from './meProvider'
import SavedTilePostItem from './savedTilePostItem'

const SavedShades = () => {
  const { likedTileIds } = useUserState()
  const meState = useMeState()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [elementWidth, setElementWidth] = useState(175)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)

    if (containerRef.current) {
      setElementWidth((containerRef.current?.clientWidth - 8) / 2)
    }

    getAllShadedTilesByIds(likedTileIds).then((fetchedTiles) => {
      if (!fetchedTiles) return
      meState.setSavedShades(fetchedTiles)
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 800)
  }, [meState.savedShades])

  if (likedTileIds.length === 0) {
    return (
      <div className="px-9 w-full h-full overflow-y-scroll">
        <span className="heading_small">
          tap the + button on a topic to see more like it. <br /> <br />
          it will also appear here.
        </span>
      </div>
    )
  }

  return (
    <div className="relative w-full bg-background-primary px-4 pb-4 overflow-y-scroll no-scrollbar scroll-smooth flex flex-col space-y-2">
      <div ref={containerRef} className="grid grid-cols-2 gap-2">
        {meState.savedShades.map((tile, idx) => {
          return <SavedTilePostItem key={idx} tile={tile} elementWidth={elementWidth} />
        })}
        {isLoading &&
          range(6).map((idx) => {
            return <LoadingPostItem key={idx} elementWidth={elementWidth} />
          })}
      </div>
    </div>
  )
}

export default SavedShades
