import { Subtile } from '~/types'
import SubtileItem from './subtileItem'

type TSubtileWrapper = {
  parentTileId: string
  subtiles: Subtile[]
  gradientColors: string[]
  subtileWrapperPlacement: number
  unreadSubtileCount?: number
  activeSubtileIdx: number
  setActiveSubtileIdx: (idx: number) => void
  showExtendedContent: boolean
  setShowExtendedContent: (flag: boolean) => void
  subtileHeights: number[]
  setSubtileHeights: (cb: (subtileHeights: number[]) => number[]) => void
  snapIntoDedicatedPositionAfterClick: (idx: number) => void
}

const SubtileWrapper = ({
  parentTileId,
  subtiles,
  gradientColors,
  subtileWrapperPlacement,
  unreadSubtileCount = 0,
  activeSubtileIdx,
  setActiveSubtileIdx,
  showExtendedContent,
  setShowExtendedContent,
  subtileHeights,
  setSubtileHeights,
  snapIntoDedicatedPositionAfterClick,
}: TSubtileWrapper) => {
  return (
    <>
      <div
        className="relative h-fit flex flex-col justify-start items-center gap-14 transition-all duration-150 ease-in-out"
        style={{ paddingTop: `${subtileWrapperPlacement}px` }}
      >
        {unreadSubtileCount !== 0 && (
          <div className="absolute top-[152px] bg-background-inverse_primary rounded-full p-2 flex justify-center items-center">
            <span className="text-content-inverse_primary paragraph_x_small">
              {`${unreadSubtileCount} new ${unreadSubtileCount > 1 ? 'shades' : 'shade'} for you`}
            </span>
          </div>
        )}
        {subtiles.map((_, idx) => (
          <SubtileItem
            parentTileId={parentTileId}
            key={idx}
            idx={idx}
            subtile={subtiles[idx]}
            color={gradientColors[idx]}
            subtileCount={subtiles.length}
            activeSubtileIdx={activeSubtileIdx}
            setActiveSubtileIdx={setActiveSubtileIdx}
            showExtendedContent={showExtendedContent}
            setShowExtendedContent={setShowExtendedContent}
            subtileHeight={subtileHeights[idx]}
            setSubtileHeights={setSubtileHeights}
            snapIntoDedicatedPositionAfterClick={snapIntoDedicatedPositionAfterClick}
          />
        ))}
      </div>
    </>
  )
}

export default SubtileWrapper
