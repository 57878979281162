'use client'

import { useSearchParams } from 'next/navigation'
import { Dispatch, useEffect, useState } from 'react'
import { connectInfiniteHits, connectSearchBox } from 'react-instantsearch-dom'
import { LightTile, SearchResults } from '~/types'
import SearchResultsGrid from './searchResultsGrid'

const SearchBox = ({ refine }: { refine: (query: string) => void }) => {
  const searchParams = useSearchParams()

  useEffect(() => {
    const q = searchParams.get('q')
    if (q) {
      refine(q)
    }
  }, [searchParams, refine])

  return null
}

const SearchHits = ({
  setResults,
  hits,
  hasMore,
  refineNext,
}: {
  setResults: Dispatch<SearchResults>
  hits: LightTile[]
  hasMore: boolean
  refineNext: () => void
}) => {
  useEffect(() => {
    setResults({ hits, hasMore, refineNext })
  }, [hits, hasMore, refineNext, setResults])
  return null
}

const ConnectedSearchBox = connectSearchBox(SearchBox)
const ConnectedSearchHits = connectInfiniteHits(SearchHits)

const SearchResultsWrapper = () => {
  const [results, setResults] = useState({} as SearchResults)

  return (
    <>
      <ConnectedSearchBox />
      <ConnectedSearchHits setResults={setResults} />
      <SearchResultsGrid results={results} />
    </>
  )
}

export default SearchResultsWrapper
