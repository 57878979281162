import { Preferences } from '@capacitor/preferences'
import { UserPreferences, UserTutorials } from '~/types'

export const setUserPreference = async (key: UserPreferences | UserTutorials, value: string) => {
  await Preferences.set({
    key,
    value,
  })
}

export const getUserPreference = async (key: UserPreferences | UserTutorials) => {
  const { value } = await Preferences.get({ key })
  return value
}
