'use client'

import { usePathname } from 'next/navigation'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { isMatchingPathname } from '~/lib/navigation'
import { TabItems, Tile, UserTakesWithTile } from '~/types'
import MeWrapper from './meWrapper'

interface IMeContext {
  mePageState: MePageState
  setMePageState: Dispatch<SetStateAction<MePageState>>
  savedTakes: UserTakesWithTile[]
  setSavedTakes: Dispatch<SetStateAction<UserTakesWithTile[]>>
  savedShades: Tile[]
  setSavedShades: Dispatch<SetStateAction<Tile[]>>
}

export const MeContext = createContext<IMeContext | null>(null)

export enum MePageState {
  SAVED_TAKES = 'saved_takes',
  SAVED_SHADES = 'saved_shades',
}

export default function MeProvider({ children }: { children: ReactNode }) {
  const pathname = usePathname() as string
  const [mePageState, setMePageState] = useState<MePageState>(MePageState.SAVED_TAKES)
  const [savedTakes, setSavedTakes] = useState<UserTakesWithTile[]>([])
  const [savedShades, setSavedShades] = useState<Tile[]>([])
  const [shouldShowMePageContent, setShouldShowMePageContent] = useState(false)

  useEffect(() => {
    setShouldShowMePageContent(isMatchingPathname(pathname, TabItems.ME))
  }, [pathname])

  const mePagePortalDOMNode = document.getElementById('me-page-portal')

  const context = {
    mePageState,
    setMePageState,
    savedTakes,
    setSavedTakes,
    savedShades,
    setSavedShades,
  }

  return (
    <MeContext.Provider value={context}>
      {children}
      {shouldShowMePageContent && mePagePortalDOMNode && ReactDOM.createPortal(<MeWrapper />, mePagePortalDOMNode)}
    </MeContext.Provider>
  )
}

export const useMeState = () => {
  const meState = useContext(MeContext)
  if (!meState) throw new Error("Can't call useMeState before context is loaded")
  return meState
}
