'use client'

import { useEffect, useState } from 'react'
import { Tile, TileTypeEnum } from '~/types'
import Image from 'next/image'
import InterestingAction from './actions/interesting'
import ShareAction from './actions/share'
import { sendEventForTile, EventTypes } from '~/lib/algoliaEvents'
import { useUserState } from '~/components/userProvider'
import { openUrlWithInAppBrowser } from '~/lib/capacitor'
import { logFirebaseEvent } from '~/lib/firebase'
import { Share } from '@capacitor/share'
import { getSourceAction, getSourceImage } from '~/lib/tileDisplay'
import ShadedTileFaviconWrapper from './shadedTileFaviconWrapper'
import { STATIC_IMAGES } from '~/lib/constants'

type TTileActionZone = {
  tile: Tile
  type: TileTypeEnum
}

const TileActionZone = ({ tile, type }: TTileActionZone) => {
  const [showShareToast, setShowShareToast] = useState(false)
  const userState = useUserState()
  const [unreadSubtileCount, setUnreadSubtileCount] = useState(0)
  const [faviconLoaded, setFaviconLoaded] = useState(false)

  useEffect(() => {
    userState.getUnreadSubtileCountForTile(tile).then((count) => {
      setUnreadSubtileCount(count)
    })
  }, [userState, tile])

  useEffect(() => {
    if (showShareToast) {
      setTimeout(() => {
        setShowShareToast(false)
      }, 1800)
    }
  }, [showShareToast])

  const share = async (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const canShare = (await Share.canShare()).value
    sendEventForTile(EventTypes.conversion, 'Share Tile', tile.id, userState.user?.id)
    logFirebaseEvent('share_tile', userState.user?.id, { tileId: tile.id, type: type })

    const tileUrl = `https://shades.news/tiles/${tile.slug}`

    if (canShare) {
      await Share.share({
        title: `see the shades: ${tile.title}`,
        text: `see the shades: ${tile.title}`,
        url: tileUrl,
        dialogTitle: 'Share to',
      })
    } else {
      navigator.clipboard.writeText(tileUrl)
      setShowShareToast(true)
    }
  }

  return (
    <div className="relative flex flex-row justify-between items-center">
      {showShareToast && (
        <div
          className={`
            ${type === TileTypeEnum.SHADED_DETAIL ? 'fixed bottom-4 left-4 right-4 h-12' : 'absolute w-full h-12'}
            bg-background-inverse_primary p-3 flex flex-col justify-center items-start opacity-100 z-10
          `}
        >
          <span className="text-content-inverse_primary paragraph_small">Link copied to clipboard</span>
        </div>
      )}
      {type === TileTypeEnum.SHADED && (
        <div className="flex flex-row gap-2 justify-start items-center">
          <ShadedTileFaviconWrapper subtiles={tile.subtiles} />
          <span className="font-sans font-normal text-[12px] leading-[14px] text-content-primary opacity-60">
            {tile.subtiles?.length} shades
          </span>
          {unreadSubtileCount > 0 && (
            <div className="bg-content-primary text-content-inverse_primary font-sans font-normal text-[12px] leading-[14px] py-[3px] px-[6px] rounded-full">
              {unreadSubtileCount} new
            </div>
          )}
        </div>
      )}
      {type === TileTypeEnum.DEVELOPING && (
        <div
          onClick={(e) => {
            e.stopPropagation()
            openUrlWithInAppBrowser(tile.sourceUrl)
          }}
          className="w-fit p-1.5 pr-2 flex flex-row gap-1 bg-background-terniary items-center"
        >
          <div className="relative h-5 w-5">
            <Image
              width={20}
              height={20}
              alt={tile.source as string}
              className="absolute inset-0 h-5 w-5 rounded-full z-[5] bg-[#D6D6D6] border border-[#2F2F2F]"
              style={{ boxShadow: '0 0 2px 2px #2F2F2F' }}
              src={getSourceImage(tile)}
              onLoad={() => setFaviconLoaded(true)}
            />
            <Image
              width={20}
              height={20}
              alt={tile.source as string}
              className="absolute inset-0 h-5 w-5 rounded-full z-[1]"
              src={STATIC_IMAGES.FAVICON_PLACEHOLDER}
            />
          </div>
          <span className="paragraph_x_small opacity-60 text-content-primary">
            {getSourceAction(tile)} {tile.source}
          </span>
        </div>
      )}
      <div className="flex flex-row gap-2 justify-end items-start ml-auto">
        <InterestingAction postItem={tile} type={type} />
        <ShareAction share={share} />
      </div>
    </div>
  )
}

export default TileActionZone
