import { TabItems } from '~/types'

export const isMatchingPathname = (pathname: string, type: TabItems) => {
  if (pathname.includes('search')) {
    return type === TabItems.SEARCH
  } else if (pathname.includes('settings')) {
    return type === TabItems.SETTINGS
  } else if (pathname.includes('games')) {
    return type === TabItems.GAMES
  } else if (pathname.includes('me')) {
    return type === TabItems.ME
  } else {
    return type === TabItems.HOME
  }
}
