'use client'

import { range } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { LightTile, SearchResults } from '~/types'
import LoadingPostItem from './loadingPostItem'
import TilePostItem from './tilePostItem'

const loadMoreTilesStaticPromise = () => new Promise((r) => setTimeout(r, 1000))

const SearchResultsGrid = ({ results }: { results: SearchResults }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [elementWidth, setElementWidth] = useState(175)
  const { ref: showMoreRef, inView: showMoreInView } = useInView({ threshold: 1 })
  const [searchHits, setSearchHits] = useState<LightTile[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [initialSearchPageLoading, setInitialSearchPageLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    if (containerRef.current) {
      setElementWidth((containerRef.current?.clientWidth - 8) / 2)
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (results.hits && results.hits.length > 0) {
      setSearchHits(results.hits)
      setTimeout(() => {
        setInitialSearchPageLoading(false)
      }, 1000)
    }
  }, [results.hits])

  useEffect(() => {
    if (showMoreInView && !isLoading) {
      setIsLoading(true)
      results.refineNext()
      const minLoadWaitPromise = loadMoreTilesStaticPromise()

      Promise.all([minLoadWaitPromise]).then(async () => {
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [showMoreInView])

  if (initialSearchPageLoading) {
    return (
      <div className="relative w-full bg-background-primary px-4 pb-4 overflow-y-scroll no-scrollbar scroll-smooth flex flex-col space-y-2">
        <div ref={containerRef} className="grid grid-cols-2 gap-2">
          {range(20).map((idx) => {
            return <LoadingPostItem key={idx} elementWidth={elementWidth} />
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="relative w-full bg-background-primary px-4 pb-4 overflow-y-scroll no-scrollbar scroll-smooth flex flex-col space-y-2">
      <div ref={containerRef} className="grid grid-cols-2 gap-2">
        {searchHits.map((tile, idx) => {
          return <TilePostItem eventName="tap_search_result" key={idx} tile={tile} elementWidth={elementWidth} />
        })}
        {isLoading &&
          range(20).map((idx) => {
            return <LoadingPostItem key={idx} elementWidth={elementWidth} />
          })}
      </div>
      {results.hasMore && !isLoading && !initialSearchPageLoading && (
        <div ref={showMoreRef} className="w-full h-4 text-center py-12" />
      )}
    </div>
  )
}

export default SearchResultsGrid
