'use client'

import { usePathname, useRouter } from 'next/navigation'
import { logFirebaseEvent } from '~/lib/firebase'
import { cloneElement } from 'react'
import { TabState, TTab } from '~/types'
import { useTabState } from './tabBarProvider'
import { useFeedState } from './home/feedProvider'

function TabBar() {
  const router = useRouter()
  const pathname = usePathname()
  const tabBarState = useTabState()
  const feedState = useFeedState()

  return (
    <div className="absolute bottom-0 bg-background-primary w-screen max-h-[72px] flex flex-row justify-around items-center p-4">
      {tabBarState.tabState.map((tab: TTab) => {
        return (
          <div
            key={tab.id}
            onClick={() => {
              logFirebaseEvent(`tap_tab_${tab.title}`)
              if (tab.navigate !== pathname) {
                router.push(tab.navigate)
              } else {
                if (pathname === '/') {
                  feedState.setResetHomeFeedToTopPosition(true)
                }
              }
            }}
            className="flex flex-col justify-end items-center h-full w-12 cursor-pointer gap-2"
          >
            {cloneElement(tab.icon[tab.state === TabState.ACTIVE ? 'active' : 'inactive'])}
            <span
              className={`label_x_small ${
                tab.state === TabState.ACTIVE ? 'text-content-primary' : 'text-content-terniary'
              }`}
            >
              {tab.title}
            </span>
            <div className={`w-full h-1 ${tab.state === TabState.ACTIVE ? 'bg-brand-quaternary' : 'bg-transparent'}`} />
          </div>
        )
      })}
    </div>
  )
}

export default TabBar
