'use client'

import { useEffect, useRef, useState } from 'react'
import { useFeedState } from '~/components/home/feedProvider'
import { Gestures, Tile, UserTutorials } from '~/types'
import { useUserState } from '~/components/userProvider'
import { logFirebaseEvent } from '~/lib/firebase'
import DevelopingTileCover from '../tiles/developingTile/developingTileCover'
import ShadedTileCover from '../tiles/shadedTile/shadedTileCover'
import { animated, useSpring } from '@react-spring/web'
import { DragState, SharedGestureState, useGesture } from '@use-gesture/react'
import { useTutorialsState } from '../tutorials/tutorialsProvider'
import { useHomeFeedVerticalGestureState } from '../gestureProvider'

const dragConfig = {
  mass: 1,
  frequency: 0.1,
  damping: 3,
  precision: 0.0001,
  clamp: true,
}

const swipeConfig = {
  mass: 1,
  frequency: 0.05,
  damping: 2,
  precision: 0.0001,
  clamp: true,
}

const minSwipeVelocity = 0.5
const minDragToSwipeVelocity = 0.3
const maxSwipeDuration = 220
const minDisplacementMultiplierToSnapNext = 0.3

function HomeFeed() {
  const feedState = useFeedState()
  const userState = useUserState()
  const tutorialsState = useTutorialsState()
  const { gestureState } = useHomeFeedVerticalGestureState()

  const homeFeedVisibleWindowRef = useRef<HTMLDivElement | null>(null)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [homeFeedVisibleWindowHeight, setHomeFeedVisibleWindowHeight] = useState(0)

  const [homeFeedSpring, homeFeedApi] = useSpring(() => ({ y: 0 }))

  const homeFeedDragBind = useGesture(
    {
      onDrag: (dragState: DragState & SharedGestureState) => {
        if (gestureState !== Gestures.HOMEFEED) return

        let hasOnGoingSwipeGesture = false

        if (dragState.memo && dragState.memo.initialPostIdx === feedState.currentPostIdx) {
          const initialPostIdx = dragState.memo.initialPostIdx
          const {
            xy: [, y],
            initial: [, yInitial],
            elapsedTime,
            dragging,
          } = dragState

          const movement = y - yInitial
          if (initialPostIdx === 0 && movement > 0) return

          const relative_velocity = movement / elapsedTime

          if (
            !hasOnGoingSwipeGesture &&
            elapsedTime < maxSwipeDuration &&
            Math.abs(relative_velocity) >= minSwipeVelocity
          ) {
            hasOnGoingSwipeGesture = true
            const swipeDirection = movement > 0 ? 'down' : 'up'
            handleSwipe(swipeDirection, initialPostIdx)
          }

          if (dragging && !hasOnGoingSwipeGesture && elapsedTime >= maxSwipeDuration) {
            handleDrag(movement, initialPostIdx)
          }

          if (!dragging && !hasOnGoingSwipeGesture) {
            handleSnapToFinalPosition(movement, relative_velocity, initialPostIdx)
          }
        }

        return {
          initialPostIdx: dragState.memo ? dragState.memo.initialPostIdx : feedState.currentPostIdx,
          hasOnGoingSwipeGesture,
        }
      },
    },
    {
      drag: {
        axis: 'y',
        filterTaps: true,
        pointer: { touch: true, capture: true },
        bounds: { bottom: 0, top: -feedState.posts.length * homeFeedVisibleWindowHeight },
      },
    },
  )

  useEffect(() => {
    if (tutorialsState.isReady) {
      if (
        tutorialsState.tutorialsStatus[UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL] &&
        tutorialsState.tutorialsStatus[UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL]
      ) {
        return
      }

      let horizontalSwipeTutorialPositionalIdx = 0

      if (!tutorialsState.tutorialsStatus[UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL]) {
        tutorialsState.setTutorialIdByType(UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL, feedState.posts[0]?.id)
        horizontalSwipeTutorialPositionalIdx += 1
      }

      if (!tutorialsState.tutorialsStatus[UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL]) {
        tutorialsState.setTutorialIdByType(
          UserTutorials.SHADED_TILE_HORIZONTAL_SWIPE_TUTORIAL,
          feedState.posts[horizontalSwipeTutorialPositionalIdx]?.id,
        )
      }
    }

    // eslint-disable-next-line
  }, [tutorialsState.isReady, feedState.posts])

  useEffect(() => {
    if (homeFeedVisibleWindowRef.current) {
      setHomeFeedVisibleWindowHeight(homeFeedVisibleWindowRef.current.getBoundingClientRect().height)
    }
  }, [homeFeedVisibleWindowRef])

  useEffect(() => {
    if (!hasLoaded) {
      logFirebaseEvent('load_homefeed', userState.user?.id)
      setHasLoaded(true)
    }
  }, [userState, hasLoaded])

  // Currently this is working but it doesnt make me feel good about it
  // This will be changed when new homefeed controlled scroll logic is completed
  // Instead of checking currentPostIdx value, tutorialsState will be updated with controlled actions
  useEffect(() => {
    if (feedState.currentPostIdx === 1) {
      tutorialsState.updateTutorialStatusByType(UserTutorials.HOMEFEED_VERTICAL_SWIPE_TUTORIAL)
    }
    // eslint-disable-next-line
  }, [feedState.currentPostIdx])

  useEffect(() => {
    if (feedState.resetHomeFeedToTopPosition) {
      homeFeedApi.start({ y: 0, config: dragConfig })
      feedState.setCurrentPostIdx(0)
    }
    // eslint-disable-next-line
  }, [feedState.resetHomeFeedToTopPosition])

  const handleSwipe = (swipeDirection: 'down' | 'up', initialPostIdx: number) => {
    if (swipeDirection === 'up' && initialPostIdx === feedState.posts.length - 1) return
    if (swipeDirection === 'down' && initialPostIdx === 0) return

    const targetPostIdx = swipeDirection === 'up' ? initialPostIdx + 1 : initialPostIdx - 1

    feedState.setCurrentPostIdx(targetPostIdx)

    homeFeedApi.start({
      y: -1 * homeFeedVisibleWindowHeight * targetPostIdx,
      config: swipeConfig,
    })
  }

  const handleDrag = (displacement: number, initialPostIdx: number) => {
    const movement = displacement - homeFeedVisibleWindowHeight * feedState.currentPostIdx

    if (initialPostIdx === 0 && movement > 0) return
    if (
      initialPostIdx === feedState.posts.length - 1 &&
      movement < homeFeedVisibleWindowHeight * feedState.posts.length
    ) {
      return
    }

    homeFeedApi.start({ y: movement, config: dragConfig })
  }

  const handleSnapToFinalPosition = (movement: number, relative_velocity: number, initialPostIdx: number) => {
    const finalGestureType = Math.abs(relative_velocity) >= minDragToSwipeVelocity ? 'swipe' : 'drag'
    const direction = movement > 0 ? 'down' : 'up'
    let targetPostIdx = initialPostIdx

    if (direction === 'down') {
      if (finalGestureType === 'swipe' && initialPostIdx !== 0) {
        targetPostIdx = initialPostIdx - 1
      }
      if (Math.abs(movement) >= homeFeedVisibleWindowHeight * minDisplacementMultiplierToSnapNext) {
        targetPostIdx = initialPostIdx - 1
      }
    }

    if (direction === 'up') {
      if (finalGestureType === 'swipe' && initialPostIdx !== feedState.posts.length - 1) {
        targetPostIdx = initialPostIdx + 1
      }
      if (Math.abs(movement) >= homeFeedVisibleWindowHeight * minDisplacementMultiplierToSnapNext) {
        targetPostIdx = initialPostIdx + 1
      }
    }

    feedState.setCurrentPostIdx(targetPostIdx)

    homeFeedApi.start({
      y: -1 * homeFeedVisibleWindowHeight * targetPostIdx,
      config: dragConfig,
    })
  }

  return (
    <div ref={homeFeedVisibleWindowRef} className="bg-background-primary h-[calc(100dvh_-_71px)] w-screen">
      <animated.div
        {...homeFeedDragBind()}
        className="absolute left-0 right-0 w-full h-fit overflow-y-hidden no-scrollbar touch-pan-y"
        style={{ y: homeFeedSpring.y }}
      >
        {feedState.posts.map((postItem: Tile, idx: number) => {
          if (postItem.developing) {
            return <DevelopingTileCover postItem={postItem} key={postItem.id} positionalIdx={idx} />
          } else {
            return <ShadedTileCover postItem={postItem} key={postItem.id} positionalIdx={idx} />
          }
        })}
      </animated.div>
    </div>
  )
}

export default HomeFeed
