import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'
import { logFirebaseEvent } from '~/lib/firebase'
import { usePrevious } from '~/lib/hooks'
import { SearchResultsPageType } from '~/types'
import { SearchCloseIcon, SearchEmptyIcon } from '../icons'
import { useCanUpdateSearchInputValue, useSearchInputValue } from './searchProvider'

const SearchInput = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const { searchInputValue, setSearchInputValue } = useSearchInputValue()
  const { canUpdateSearchInputValue, setCanUpdateSearchInputValue } = useCanUpdateSearchInputValue()
  const prevSearchInputValue = usePrevious<string>(searchInputValue)
  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const type = searchParams.get('t')
  const query = searchParams.get('q')

  useEffect(() => {
    if (type && query && Object.values(SearchResultsPageType).includes(type as SearchResultsPageType)) {
      router.push(`/search?t=${type}&q=${query}`)
    }
    if (prevSearchInputValue !== null && prevSearchInputValue !== '' && searchInputValue === '') {
      router.push('/search')
    }

    // eslint-disable-next-line
  }, [searchInputValue, type, query])

  return (
    <div className="relative w-full p-4">
      <SearchEmptyIcon className="absolute h-5 w-5 left-8 top-[34px] stroke-content-terniary" />
      <form
        onSubmit={(e) => {
          e.preventDefault()
          if (!searchInputValue) return
          logFirebaseEvent('search_input', null, { value: searchInputValue })
          router.push(`/search?t=${SearchResultsPageType.SEARCH}&q=${searchInputValue}`)
          searchInputRef.current?.blur()
        }}
      >
        <input
          ref={searchInputRef}
          placeholder="search for anything"
          autoCapitalize="off"
          onFocus={() => {
            if (!canUpdateSearchInputValue) setCanUpdateSearchInputValue(true)
          }}
          onChange={(e) => {
            setSearchInputValue(e.target.value as string)
          }}
          onBlur={() => {
            if (canUpdateSearchInputValue) setCanUpdateSearchInputValue(false)
          }}
          value={searchInputValue}
          inputMode="search"
          className="w-full h-14 py-auto px-11 bg-background-primary border-[2px] border-stroke-opaque placeholder:text-content-terniary focus:outline-none focus:border-stroke-selected"
        />
      </form>
      {searchInputValue && (
        <SearchCloseIcon
          className="absolute h-8 w-8 fill-content-terniary right-8 top-[28px] text-opacity-40"
          onClick={() => {
            logFirebaseEvent('cancel_search')
            setSearchInputValue('')
          }}
        />
      )}
    </div>
  )
}

export default SearchInput
