'use client'

import Image from 'next/image'
import { useRouter, useSearchParams } from 'next/navigation'
import { IMAGE_ASPECT_RATIOS, SEARCH_PAGE_IMAGE_ASPECT_RATIO, STATIC_IMAGES } from '~/lib/constants'
import { LightTile } from '~/types'
import { sendEventForTile, EventTypes } from '~/lib/algoliaEvents'
import { useUserState } from '~/components/userProvider'
import { httpsCallable } from 'firebase/functions'
import { functions, logFirebaseEvent } from '~/lib/firebase'
import TileTitle from '~/components/tiles/tileTitle'

type TTilePostItem = {
  tile: LightTile
  elementWidth: number
  eventName?: string
}

const TilePostItem = ({ tile, elementWidth, eventName }: TTilePostItem) => {
  const router = useRouter()
  const userState = useUserState()
  const searchParams = useSearchParams()

  const type = searchParams.get('t')
  const query = searchParams.get('q')

  const handleTileClick = () => {
    sendEventForTile(EventTypes.click, 'Tap Tile', tile.id, userState.user?.id)
    const tappedPost = httpsCallable(functions, 'userEvents-tap_tile')
    logFirebaseEvent(eventName ? eventName : 'tap_tile', userState.user?.id, { tileId: tile.id })
    tappedPost({ tileId: tile.id })
    router.push(`/search/tiles/${tile.slug}?t=${type}&q=${query}`)
  }

  const imageUrl =
    tile.imageUrls.find((image) => image.aspectRatio === IMAGE_ASPECT_RATIOS['1X2'])?.url || STATIC_IMAGES.PLACEHOLDER

  return (
    <div onClick={handleTileClick} className="relative w-full h-full">
      <Image
        src={`${imageUrl}?w=${Math.floor(elementWidth)}&q=75&dpr=2&auto=format`}
        alt={tile.title}
        width={elementWidth}
        height={elementWidth / SEARCH_PAGE_IMAGE_ASPECT_RATIO}
        className="object-cover object-left-top"
        style={{ height: elementWidth / SEARCH_PAGE_IMAGE_ASPECT_RATIO }}
      />
      <div className="absolute bottom-3 left-2 right-3 max-w-[calc(100%_-_24px)] z-20">
        <TileTitle type="search" title={tile.title} />
      </div>
    </div>
  )
}

export default TilePostItem
