'use client'

import { MePageState, useMeState } from './meProvider'

const MePageStateSelector = () => {
  const meState = useMeState()
  const MePageStateKeys = Object.keys(MePageState) as Array<keyof typeof MePageState>

  return (
    <div className="my-9 w-full h-[52px] flex flex-col justify-center items-center">
      <div className="flex flex-row justify-center items-center h-[52px]">
        {MePageStateKeys.map((key) => {
          return (
            <div
              key={key}
              onClick={() => {
                meState.setMePageState(MePageState[key])
              }}
              className={`
                p-4 leading-5 text-[18px] text-content-primary min-w-[110px] text-center cursor-pointer border-2 border-white
                ${
                  MePageState[key] === MePageState.SAVED_TAKES
                    ? 'rounded-tl-full rounded-bl-full border-r-0'
                    : 'rounded-tr-full rounded-br-full'
                }
                ${MePageState[key] === meState.mePageState ? 'bg-brand-quaternary' : 'bg-transparent'}
              `}
            >
              {MePageState[key] === MePageState.SAVED_TAKES ? 'my takes' : 'my +'}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MePageStateSelector
