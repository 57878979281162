import { useState } from 'react'
import Image from 'next/image'
import ShadesLogo from 'assets/shades_logo_3d.png'
const SplashScreen = () => {
  const [loaded, setLoaded] = useState(false)
  return (
    <>
      {!loaded && <div className={`absolute h-screen w-screen bg-black z-50`} />}
      <div
        className={`absolute h-screen w-screen flex flex-col justify-center items-center text-content-primary cf-splash-background heading_small gap-4 z-50`}
        style={{ visibility: loaded ? 'visible' : 'hidden' }}
      >
        <Image
          src={ShadesLogo}
          alt="shades logo"
          width={454}
          height={198}
          className="mx-auto w-[calc(100%_-_50px)]"
          onLoad={() => {
            setLoaded(true)
          }}
        />
      </div>
    </>
  )
}

export default SplashScreen
